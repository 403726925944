import { FC } from 'react';
import styled from 'styled-components/macro';

const BlockList: FC = styled('ul')`
  list-style: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
`;

export default BlockList;
