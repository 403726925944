import React, { FC, useCallback, useMemo } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useAppState } from './state';
import ErrorDialog from './components/ErrorDialog';
import { AudioProvider } from './components/AudioProvider';
import LoginPage from './pages/LoginPage';
import DiscussionPage from './pages/DiscussionPage';
import DashboardPage from './pages/DashboardPage';
import generateConnectionOptions from './utils/generateConnectionOptions';
import Spinner from './components/Spinner';
import useAsyncEffect from './hooks/useAsyncEffect';
import { api } from './firebaseApp';

const App: FC = () => {
  const { error, appUser, user, settings, isAuthReady, setError } = useAppState();
  const dismissError = useCallback(() => setError(null), [setError]);
  const audioConnectionOptions = useMemo(() => generateConnectionOptions(settings), [settings]);
  const shouldRegister = user && !appUser;

  useAsyncEffect(async () => {
    if (shouldRegister) {
      await api.createAppUser();
    }
  }, [shouldRegister]);

  if (!isAuthReady) {
    // Waiting to determine if the user is logged in
    return <Spinner />;
  }

  if (!user || !appUser) {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Route path="/discussion/:discussionId">
              <LoginPage />
            </Route>
            <Route path="/">
              <LoginPage />
            </Route>
          </Switch>
        </BrowserRouter>
        <ErrorDialog dismissError={dismissError} error={error} />
      </>
    );
  }

  return (
    <AudioProvider options={audioConnectionOptions} onError={setError}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <DashboardPage />
          </Route>
          <Route path="/hosts">
            <DashboardPage />
          </Route>
          <Route path="/discussion/:discussionId">
            <DiscussionPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
      <ErrorDialog dismissError={dismissError} error={error} />
    </AudioProvider>
  );
};

export default App;
