import React, { FC } from 'react';
import * as Twilio from 'twilio-video';
import styled from 'styled-components/macro';
import { DiscussionParticipant } from 'functions/lib/types/DiscussionParticipant';
import { canSpeak } from 'functions/lib/utils/canSpeak';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import { useAudioLevel } from '../../hooks/useAudioLevel';
import AudioTrack from '../AudioTrack';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import { useDiscussionContext } from './DiscussionContext';
import useAudioContext from '../../hooks/useAudioContext/useAudioContext';
import { dimensions } from './constants';

interface ParticipantAudioTrackProps {
  participant: DiscussionParticipant;
  hideVolumeIndicator: boolean;
}

const ParticipantAudioTrack: FC<ParticipantAudioTrackProps> = ({ participant, hideVolumeIndicator, ...rest }) => {
  const { userId, muted, discussion } = useDiscussionContext();
  const { twilioRoom } = useAudioContext();
  const twilioParticipants = useParticipants();
  const isLocalUser = userId === participant.id;
  const twilioParticipant =
    participant.id === userId
      ? twilioRoom?.localParticipant
      : twilioParticipants.find(p => p.identity === participant.id);

  const publications = usePublications(twilioParticipant || null);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as Twilio.AudioTrack | undefined;
  const audioLevel = useAudioLevel(audioTrack);

  if (audioTrack && !(isLocalUser && muted) && canSpeak(discussion, participant.id)) {
    return (
      <div {...rest}>
        {!hideVolumeIndicator && (
          <AudioLevelOverlay
            style={{
              height: `${audioLevel * 100}%`,
            }}
          />
        )}
        {!isLocalUser && <AudioTrack track={audioTrack} />}
      </div>
    );
  }

  return null;
};

export default styled(ParticipantAudioTrack)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${Math.ceil(dimensions.PARTICIPANT_CIRCLE_RADIUS)}px;
  overflow: hidden;
`;

const AudioLevelOverlay = styled('div')`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 2;
  pointer-events: none;
`;
