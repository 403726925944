import React, { FC } from 'react';
import styled from 'styled-components/macro';
import DiscussionModal, { DiscussionModalProps, DiscussionModalValue } from './DiscussionModal';

export type CreateDiscussionModalValue = DiscussionModalValue;

const initialValue: CreateDiscussionModalValue = {
  name: '',
  allowGuests: false,
};

type CreateDiscussionModalProps = Omit<DiscussionModalProps, 'value' | 'title' | 'spinnerText' | 'submitButtonText'>;

const CreateDiscussionModal: FC<CreateDiscussionModalProps> = props => (
  <DiscussionModal
    {...props}
    title="Create a New Circle"
    spinnerText="Creating Circle"
    submitButtonText="Create Circle"
    value={initialValue}
  />
);

export default styled(CreateDiscussionModal)``;
