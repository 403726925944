import { useState, useEffect } from 'react';
import { StorageImageObject, isStorageImageObject } from 'functions/lib/types/StorageImageObject';
import { firebaseApp } from '../firebaseApp';

const downloadUrlCache = new Map<string, Promise<string>>();

export function useStorageObjectUrl(storageObject: StorageImageObject | string | undefined | null): string | null {
  const [url, setUrl] = useState<string | null>(null);

  // Storage object
  useEffect(() => {
    if (isStorageImageObject(storageObject)) {
      let downloadUrlPromise = downloadUrlCache.get(storageObject.path);

      if (!downloadUrlPromise) {
        setUrl(null);
        downloadUrlPromise = firebaseApp
          .storage()
          .ref(storageObject.path)
          .getDownloadURL();

        downloadUrlCache.set(storageObject.path, downloadUrlPromise);
      }

      downloadUrlPromise.then(setUrl);
    } else if (typeof storageObject === 'string') {
      setUrl(storageObject);
    } else {
      setUrl(null);
    }
  }, [storageObject]);

  return url;
}
