import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useAudioContext from '../useAudioContext/useAudioContext';

export default function useParticipants() {
  const { twilioRoom } = useAudioContext();
  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);

  useEffect(() => {
    if (twilioRoom) {
      setParticipants(Array.from(twilioRoom.participants.values()));
    }
  }, [twilioRoom]);

  useEffect(() => {
    if (!twilioRoom) {
      return;
    }

    const participantConnected = (participant: RemoteParticipant) =>
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
    twilioRoom.on('participantConnected', participantConnected);
    twilioRoom.on('participantDisconnected', participantDisconnected);
    return () => {
      twilioRoom.off('participantConnected', participantConnected);
      twilioRoom.off('participantDisconnected', participantDisconnected);
    };
  }, [twilioRoom]);

  return participants;
}
