import { Room } from 'twilio-video';
import { useEffect } from 'react';

import { Callback } from '../../types';

export default function useHandleTrackPublicationFailed(room: Room | null, onError: Callback) {
  const localParticipant = room ? room.localParticipant : null;
  useEffect(() => {
    if (!localParticipant) {
      return;
    }
    if (localParticipant) {
      localParticipant.on('trackPublicationFailed', onError);
      return () => {
        localParticipant.off('trackPublicationFailed', onError);
      };
    }
  }, [localParticipant, onError]);
}
