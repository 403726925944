import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';

import { Callback } from '../../types';

export default function useHandleRoomDisconnectionErrors(room: Room | null, onError: Callback) {
  useEffect(() => {
    if (!room) {
      return;
    }
    // eslint-disable-next-line no-shadow
    const onDisconnected = (_: Room, error: TwilioError) => {
      if (error) {
        onError(error);
      }
    };

    room.on('disconnected', onDisconnected);
    return () => {
      room.off('disconnected', onDisconnected);
    };
  }, [room, onError]);
}
