import React, { FC, ReactNode, FormEvent, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import BaseForm from '@emberex/components/lib/Form';
import Modal, { ModalProps } from '../Modal';
import PillButton from '../PillButton';
import Spinner from '../Spinner';
import TextInput from '../TextInput';

export interface HostModalValue {
  firstName: string;
  lastName: string;
  email: string;
}

export interface HostModalProps extends ModalProps {
  title: ReactNode;
  spinnerText: string;
  submitButtonText: string;
  value: HostModalValue;
  onSubmit(value: HostModalValue): unknown;
}

const HostModal: FC<HostModalProps> = ({ onSubmit, title, value, submitButtonText, spinnerText, ...rest }) => {
  const [firstName, setFirstName] = useState(value.firstName);
  const [lastName, setLastName] = useState(value.lastName);
  const [email, setEmail] = useState(value.email);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);

  const handleFirstNameChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setFirstName(event.currentTarget.value);
  }, []);

  const handleLastNameChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setLastName(event.currentTarget.value);
  }, []);

  const handleEmailChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await onSubmit({ firstName, lastName, email });
      setSubmitError(null);
    } catch (error) {
      setSubmitError(error);
    } finally {
      setSubmitting(false);
    }
  }, [onSubmit, firstName, lastName, email]);

  return (
    <Modal {...rest}>
      <Form onSubmit={handleSubmit}>
        <Title>{title}</Title>
        <ErrorText visible={!!submitError}>{submitError?.message}</ErrorText>
        <TextInput label="First Name" value={firstName} onChange={handleFirstNameChange} autofocus />
        <TextInput label="Last Name" value={lastName} onChange={handleLastNameChange} />
        <TextInput label="Email" value={email} onChange={handleEmailChange} />
        <SubmitButton>{submitButtonText}</SubmitButton>
      </Form>
      {submitting && <Spinner message={spinnerText} />}
    </Modal>
  );
};

export default styled(HostModal)`
  padding: 4rem 10rem 8rem 10rem;
`;

const Form = styled(BaseForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorText = styled('p')<{ visible: boolean }>`
  background-color: rgba(160, 0, 0, 0.75);
  min-height: 2.25rem;
  box-sizing: border-box;
  flex-shrink: 0;
  color: white;
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 1rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

const Title = styled('h1')`
  color: rgba(51, 51, 51, 0.98);
  font-size: 2rem;
  text-align: center;
  margin: 0 0 1rem 0;
  font-weight: normal;
`;

const SubmitButton = styled(PillButton)`
  width: 20rem;
  margin-top: 2.5rem;
`;
