import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { paths } from 'functions/lib/paths';
import { Discussion } from 'functions/lib/types/Discussion';
import { useAppState } from '../state';
import googleLogoSrc from '../images/google-logo.png';
import { IoIosArrowRoundForward } from 'react-icons/io';
import Spinner from '../components/Spinner';
import { ALLOW_GUEST_LOGIN } from '../env';
import Logo from '../components/Logo';
import PillButton from '../components/PillButton';
import useAsyncEffect from '../hooks/useAsyncEffect';
import { getDoc } from '../firebaseApp';

interface UrlParams {
  discussionId?: string;
}

const LoginPage: FC = props => {
  const { discussionId } = useParams<UrlParams>();
  const { signInWithGoogle, signInAsGuest, isAuthReady } = useAppState();
  const [signingIn, setSigningIn] = useState(false);
  const [showGuestLogin, setShowGuestLogin] = useState(false);

  /**
   * If there's a discussion id in the URL, check if it allows guests.
   */
  useAsyncEffect(async () => {
    if (discussionId) {
      const discussion = (await getDoc<Discussion>(paths.discussion(discussionId)).get()).data();
      setShowGuestLogin(discussion?.allowGuests ?? false);
    }
  }, [discussionId]);

  const handleSignInWithGoogle = useCallback(async () => {
    try {
      setSigningIn(true);
      await signInWithGoogle();
    } catch (error) {
      console.error('Auth error', error);
    } finally {
      setSigningIn(false);
    }
  }, [signInWithGoogle]);

  const handleSignInAsGuest = useCallback(async () => {
    try {
      setSigningIn(true);
      await signInAsGuest();
    } catch (error) {
      console.error('Auth error', error);
    } finally {
      setSigningIn(false);
    }
  }, [signInAsGuest]);

  if (!isAuthReady) {
    // Waiting for firebase to determine if the user is logged-in or not
    return (
      <div {...props}>
        <Spinner />
      </div>
    );
  }

  return (
    <div {...props}>
      <Content>
        <Logo />
        <GoogleLoginButton onClick={handleSignInWithGoogle} disabled={signingIn}>
          Sign in with <img src={googleLogoSrc} alt="Google" /> <IoIosArrowRoundForward size={30} />
        </GoogleLoginButton>

        {ALLOW_GUEST_LOGIN && showGuestLogin && (
          <GuestLoginButton onClick={handleSignInAsGuest} disabled={signingIn}>
            Sign in as guest <IoIosArrowRoundForward size={30} />
          </GuestLoginButton>
        )}
      </Content>
    </div>
  );
};

export default styled(LoginPage)`
  min-height: 100vh;
`;

const Content = styled('div')`
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding: 10rem 6rem 10rem 6rem;
`;

const LoginButton = styled(PillButton)`
  flex-shrink: 0;
  background-color: #f2f2f2;
  color: black;
  border-radius: 5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &:hover {
    background-color: #c8c8c8;
  }

  > svg {
    margin-left: 2.5rem;
  }
`;

const GoogleLoginButton = styled(LoginButton)`
  margin-top: 4.5rem;

  > img {
    height: 33px;
    width: 100px;
    margin-left: 0.75rem;
  }
`;

const GuestLoginButton = styled(LoginButton)`
  margin-top: 2rem;
`;
