import React, { FC, FormEvent, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDiscussionContext } from './DiscussionContext';
import { DiscussionParticipant } from 'functions/lib/types/DiscussionParticipant';
import StorageImage from '../StorageImage';
import TextInput from '../TextInput';
import { RightArrowIcon, UploadIcon } from '../icons';
import { hasDiscussionStarted } from 'functions/lib/utils/hasDiscussionStarted';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import profilePicPlaceholderSrc from '../../images/profile-pic-placeholder.png';
import { colors } from './constants';
import Spinner from '../Spinner';

const ProfileMenu: FC = props => {
  const {
    userId,
    discussion,
    removingProfilePicture,
    settingProfilePicture,
    setProfilePicture,
    setName,
    endDiscussion,
    leave,
  } = useDiscussionContext();
  const participant: DiscussionParticipant | undefined = discussion.participants[userId];
  const participantName = participant?.name ?? '';
  const [isOpen, setIsOpen] = useState(false);
  const [nameInputValue, setNameInputValue] = useState(participantName);
  const canEndDiscussion = isDiscussionHost(discussion, userId) && hasDiscussionStarted(discussion);
  const profilePicSrc = removingProfilePicture
    ? profilePicPlaceholderSrc
    : participant.profilePic ?? profilePicPlaceholderSrc;

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const saveName = useCallback(() => {
    if (participantName !== nameInputValue) {
      setName(nameInputValue);
    }
  }, [setName, participantName, nameInputValue]);

  const leaveDiscussion = useCallback(async () => {
    if (canEndDiscussion) {
      await endDiscussion();
    } else {
      await leave();
    }
  }, [canEndDiscussion, endDiscussion, leave]);

  const handleProfilePicChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const { files } = event.currentTarget;
      if (files && files.length > 0) {
        setProfilePicture(files[0]);
      } else {
        setProfilePicture(null);
      }
    },
    [setProfilePicture]
  );

  const handleRemoveProfilePic = useCallback(() => {
    setProfilePicture(null);
  }, [setProfilePicture]);

  const handleNameChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setNameInputValue(event.currentTarget.value);
  }, []);

  useEffect(() => {
    setNameInputValue(participantName);
  }, [participantName]);

  if (!isOpen) {
    return (
      <div {...props}>
        <OpenButton onClick={open}>
          <ProfilePicture src={profilePicSrc} alt="Profile picture" />
        </OpenButton>
      </div>
    );
  }

  return (
    <OutsideClickHandler onOutsideClick={close}>
      <div {...props}>
        <Window>
          <LogoutButton onClick={leaveDiscussion}>
            Logout
            <RightArrowIcon />
          </LogoutButton>
          <ProfilePicWrapper>
            <ProfilePicCircle forceShowUploadbutton={!participant.profilePic}>
              <FileUploadButton>
                <UploadIcon size={40} />
                <span>upload photo</span>
                <input type="file" onChange={handleProfilePicChange} />
              </FileUploadButton>
              {settingProfilePicture || removingProfilePicture ? <Spinner /> : null}
              <ProfilePicture src={profilePicSrc} alt="Profile picture" />
            </ProfilePicCircle>
            {participant.profilePic ? <RemoveProfilePicButton onClick={handleRemoveProfilePic} /> : null}
          </ProfilePicWrapper>
          <TextInput
            value={nameInputValue}
            onChange={handleNameChange}
            onBlur={saveName}
            disabled={!participant || !participant.isGuest}
          />
        </Window>
      </div>
    </OutsideClickHandler>
  );
};

export default styled(ProfileMenu)`
  position: absolute;
  right: 1.5rem;
  bottom: 3rem;
  z-index: 10;
`;

const OpenButton = styled('button')`
  box-sizing: border-box;
  height: 3rem;
  width: 3rem;
  border: 5px solid #1e3da2;
  border-radius: 50%;
  overflow: hidden;
  padding: 0 0 0 0;
`;

const ProfilePicture = styled(StorageImage)`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`;

const Window = styled('div')`
  box-sizing: border-box;
  width: 18rem;
  padding: 2.5rem 1.5rem 2rem 1.5rem;
  border: 6px solid #1e3da2;
  border-radius: 1rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FileUploadButton = styled('label')`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #0f014a;
  font-size: 0.75rem;
  background-color: rgba(32, 106, 197, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }
`;

const ProfilePicWrapper = styled('div')`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
`;

const ProfilePicCircle = styled('div')<{ forceShowUploadbutton: boolean }>`
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  height: 7.25rem;
  width: 7.25rem;
  border: 0.5rem solid #1e3da2;
  border-radius: 50%;
  align-self: center;
  margin-bottom: 1.5rem;
  overflow: hidden;

  > ${FileUploadButton} {
    opacity: ${props => (props.forceShowUploadbutton ? 1 : 0)};
    transition: opacity 0.2s;
  }

  &:hover > ${FileUploadButton} {
    opacity: 1;
  }
`;

const RemoveProfilePicButton = styled('button')`
  box-sizing: border-box;
  height: 1.6rem;
  width: 1.6rem;
  border: 0.25rem solid ${colors.BLUE};
  border-radius: 50%;
  background-color: ${colors.LIGHT_BLUE};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(2.5rem, -3rem) translate(-50%, -50%);
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.LIGHTER_BLUE};
  }

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0.8rem;
    height: 3px;
    border-radius: 1.5px;
    background-color: ${colors.BLUE};
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const LogoutButton = styled('button')`
  position: absolute;
  top: 3px;
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 6px solid ${colors.BLUE};
  font-weight: bold;
  border-radius: 1.125rem;
  font-size: 0.9rem;
  background-color: ${colors.LIGHT_BLUE};
  height: 2.25rem;
  width: 7.25rem;
  color: white;

  img {
    margin-left: 0.75rem;
  }
`;
