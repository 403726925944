import React, { FC } from 'react';
import { TwilioError } from 'twilio-video';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

interface ErrorDialogProps {
  error: Error | TwilioError | null;
  dismissError: () => void;
}

const ErrorDialog: FC<ErrorDialogProps> = ({ dismissError, error }) => {
  const { message, code, warning } = (error || {}) as any;
  const enhancedMessage = enhanceMessage(message, code);

  return (
    <Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
      <DialogTitle>{warning ? 'WARNING' : 'ERROR'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{enhancedMessage}</DialogContentText>
        {code && (
          <pre>
            <code>Error Code: {code}</code>
          </pre>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissError()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
function enhanceMessage(message = '', code?: number) {
  switch (code) {
    case 20101: // Invalid token error
      return message + '. Please make sure you are using the correct credentials.';
    default:
      return message;
  }
}

export default ErrorDialog;
