import { useEffect, useRef, useState } from 'react';
import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import useIsTrackEnabled from '../hooks/useIsTrackEnabled/useIsTrackEnabled';

const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
let audioContext: AudioContext;

function initializeAnalyser(stream: MediaStream): AnalyserNode {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.4;
  analyser.fftSize = 512;

  audioSource.connect(analyser);
  return analyser;
}

export function useAudioLevel(audioTrack: AudioTrack | undefined): number {
  const mediaStreamRef = useRef<MediaStream>();
  const isTrackEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack | undefined);
  const [audioLevel, setAudioLevel] = useState(0);

  useEffect(() => {
    // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
    // we stop the cloned track that is stored in mediaStreamRef. It is important that we stop
    // all tracks when they are not in use. Browsers like Firefox don't let you create a stream
    // from a new audio device while the active audio device still has active tracks.
    const handleStopped = () => mediaStreamRef.current?.getTracks().forEach(track => track.stop());
    audioTrack?.on('stopped', handleStopped);
    return () => {
      audioTrack?.off('stopped', handleStopped);
    };
  }, [audioTrack]);

  useEffect(() => {
    if (audioTrack && isTrackEnabled) {
      // Here we create a new MediaStream from a clone of the mediaStreamTrack.
      // A clone is created to allow multiple instances of this component for a single
      // AudioTrack on iOS Safari. It is stored in a ref so that the cloned track can be stopped
      // when the original track is stopped.
      mediaStreamRef.current = new MediaStream([audioTrack.mediaStreamTrack.clone()]);
      let analyser = initializeAnalyser(mediaStreamRef.current);

      const reinitializeAnalyser = () => {
        analyser = initializeAnalyser(mediaStreamRef.current!);
      };

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser);

      const sampleArray = new Uint8Array(analyser.frequencyBinCount);

      const interval = setInterval(() => {
        analyser.getByteFrequencyData(sampleArray);
        let values = 0;

        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        // Clamp the volume between 0 and 1 (inclusive)
        const volume = Math.min(1, Math.max(0, Math.log10(values / length / 6)));
        setAudioLevel(volume);
      }, 50);

      return () => {
        clearInterval(interval);
        window.removeEventListener('focus', reinitializeAnalyser);
      };
    }
  }, [audioTrack, isTrackEnabled]);

  return isTrackEnabled ? audioLevel : 0;
}
