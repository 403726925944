import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { RadialLayoutChild } from '../RadialLayout';
import { colors, dimensions } from './constants';

const Rim: FC = props => {
  return <RadialLayoutChild {...props} />;
};

export default styled(Rim)`
  border: ${dimensions.RIM_THICKNESS}px solid ${colors.BLUE};
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
