import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { UserIcon } from '../icons';
import { colors } from './constants';
import { useDiscussionContext } from './DiscussionContext';

const ParticipantCountLabel: FC = props => {
  const { userId, discussion } = useDiscussionContext();

  // Only the host sees the participant count
  if (!isDiscussionHost(discussion, userId)) {
    return null;
  }

  const participantCount = discussion.connectedParticipantIds.length;

  return (
    <div {...props}>
      <ParticipantIcon />
      <CountText>
        {participantCount} Participant{participantCount !== 1 ? 's' : ''}
      </CountText>
    </div>
  );
};

export default styled(ParticipantCountLabel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 8rem;
  left: 1.5rem;
`;

const CountText = styled('div')`
  color: white;
  font-size: 1rem;
  margin-right: 0.5rem;
`;

const ParticipantIcon = styled(UserIcon)`
  margin-right: 0.5rem;
  color: ${colors.PINK};
`;
