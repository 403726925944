import { parseDate } from './parseDate';

export function sortByDate(a: unknown, b: unknown, reversed = false): number {
  const dateA = parseDate(a);
  const dateB = parseDate(b);
  const multiplier = reversed ? -1 : 1;

  if (!dateA && !dateB) {
    return 0;
  }
  if (!dateA) {
    return 1 * multiplier;
  }
  if (!dateB) {
    return -1 * multiplier;
  }

  const timestampA = dateA.getTime();
  const timestampB = dateB.getTime();

  return (timestampA - timestampB) * multiplier;
}
