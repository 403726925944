import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { EnvelopeIcon } from '../icons';

interface NewMessageToastProps {
  onClick(): unknown;
}

const NewMessageToast: FC<NewMessageToastProps> = props => {
  return (
    <button {...props}>
      <EnvelopeIcon /> New message
    </button>
  );
};

export default styled(NewMessageToast)`
  position: absolute;
  bottom: 2.85rem;
  right: 6rem;
  height: 3rem;
  width: 14rem;
  border: 6px solid #0f014a;
  border-radius: 1.5rem;
  background-color: #c92db4;
  color: #fffff0;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin: 0 0.75rem 0 1.5rem;
    font-size: 1.5rem;
  }
`;
