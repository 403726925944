import isPlainObject from 'is-plain-object';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

let checkingPermissionsPromise: Promise<void> | null = null;

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export async function ensureMediaPermissions(): Promise<void> {
  if (checkingPermissionsPromise) {
    // Ignore repeated calls to this function if it's already running
    return checkingPermissionsPromise;
  }

  let promise: Promise<void> | null = null;
  try {
    promise = checkingPermissionsPromise = Promise.resolve().then(async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const shouldAskForMediaPermissions = devices.every(device => !(device.deviceId && device.label));
      if (shouldAskForMediaPermissions) {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

        for (const track of mediaStream.getTracks()) {
          track.stop();
        }
      }
    });
    await promise;
  } finally {
    if (promise === checkingPermissionsPromise) {
      checkingPermissionsPromise = null;
    }
  }
}

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}
