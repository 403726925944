import { useCallback, useEffect, useState } from 'react';

import { ensureMediaPermissions } from '../../utils';
import TwilioVideo, { LocalAudioTrack, CreateLocalTrackOptions } from 'twilio-video';

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack | undefined>();
  const [micPermissionGranted, setMicPermissionGranted] = useState(true); // Assume mic permissions are granted until `createLocalAudioTrack` fails
  const [micPermissionRequested, setMicPermissionRequested] = useState(false);

  const getLocalAudioTrack = useCallback(async (deviceId?: string) => {
    try {
      const options: CreateLocalTrackOptions = {};

      if (deviceId) {
        options.deviceId = { exact: deviceId };
      }

      await ensureMediaPermissions();
      const newTrack = await TwilioVideo.createLocalAudioTrack(options);
      setTrack(newTrack);
      setMicPermissionGranted(true);
      setMicPermissionRequested(true);
      return newTrack;
    } catch (error) {
      console.error('Failed to get audio track', error);
      setMicPermissionGranted(false);
      setMicPermissionRequested(true);
    }
  }, []);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return { track, micPermissionGranted, micPermissionRequested, getLocalAudioTrack };
}

export default function useLocalTracks() {
  const { track: audioTrack, micPermissionGranted, micPermissionRequested, getLocalAudioTrack } = useLocalAudioTrack();

  const localTracks = [audioTrack].filter(track => track !== undefined) as LocalAudioTrack[];

  return { localTracks, micPermissionGranted, micPermissionRequested, getLocalAudioTrack };
}
