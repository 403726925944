import styled from 'styled-components/macro';
import starSrc from '../images/star.png';
import rightArrowSrc from '../images/right-arrow.png';
import removeIconSrc from '../images/remove-icon.png';
import takeStickIconSrc from '../images/take-stick-icon.png';

export {
  FaMicrophone as MicIcon,
  FaMicrophoneSlash as MicMutedIcon,
  FaHandPaper as HandIcon,
  FaPlus as PlusIcon,
  FaUserAlt as UserIcon,
  FaCommentAlt as MessageIcon,
  FaThumbtack as ThumbtackIcon,
  FaCloudUploadAlt as UploadIcon,
  FaImage as PhotoIcon,
  FaEyeSlash as NotViewingIcon,
  FaPencilAlt as PencilIcon,
  FaEnvelope as EnvelopeIcon,
  FaChevronLeft as ChevronLeftIcon,
} from 'react-icons/fa';

export {
  IoMdArrowUp as ArrowIcon,
  IoIosClose as XIcon,
  IoIosShuffle as ShuffleIcon,
  IoMdArrowDropdown as TriangleDownIcon,
} from 'react-icons/io';

export const StarIcon = styled('img').attrs({ src: starSrc, alt: '' })`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export const RightArrowIcon = styled('img').attrs({ src: rightArrowSrc, alt: '' })`
  width: 18px;
  height: 16px;
  flex-shrink: 0;
`;

export const RemoveIcon = styled('img').attrs({ src: removeIconSrc, alt: 'Remove' })`
  width: 72px;
  height: 72px;
  flex-shrink: 0;
`;

export const TakeStickIcon = styled('img').attrs({ src: takeStickIconSrc, alt: 'Take Stick' })`
  width: 72px;
  height: 72px;
  flex-shrink: 0;
`;
