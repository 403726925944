import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';

interface AddMenuProps {
  onCreateCircle(): unknown;
  onCreateHost(): unknown;
  showAddHostButton: boolean;
}

const AddMenu: FC<AddMenuProps> = ({ onCreateCircle, onCreateHost, showAddHostButton, ...rest }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpen = useCallback(() => {
    setMenuOpen(value => !value);
  }, []);

  return (
    <div {...rest}>
      <PlusButton onClick={toggleMenuOpen}>
        <span>+</span>
      </PlusButton>
      {menuOpen && (
        <OutsideClickHandler onOutsideClick={toggleMenuOpen}>
          <Menu>
            <MenuButton
              onClick={() => {
                toggleMenuOpen();
                onCreateCircle();
              }}
            >
              Create a New Circle
            </MenuButton>
            {showAddHostButton && (
              <MenuButton
                onClick={() => {
                  toggleMenuOpen();
                  onCreateHost();
                }}
              >
                Add a New Host
              </MenuButton>
            )}
          </Menu>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default styled(AddMenu)`
  width: 4rem;
  position: relative;
`;

const PlusButton = styled('button')`
  width: 4rem;
  height: 4rem;
  background-color: #c92cb4;
  border: none;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  line-height: 2rem;
  color: white;
  font-size: 3rem;
  border-radius: 50%;

  > span {
    position: relative;
    top: -3px;
  }
`;

const Menu = styled('div')`
  position: absolute;
  width: 19rem;
  right: -1.5rem;
  top: 50%;
  transform: translate(100%, -50%);
  padding: 0.5rem 0.6rem 0.5rem 0.6rem;
  background-color: #c92db4;
  color: white;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  z-index: 10;
`;

const MenuButton = styled('button')`
  color: white;
  padding: 1rem 1.25rem 1rem 1.25rem;
  text-align: left;
  font-size: 1.1rem;
  margin: 0 0 0 0;
  max-width: none;
  min-width: 0;
  background-color: #ba29a6;
  border-radius: 0.5rem;
  border: none;

  & + & {
    margin-top: 0.25rem;
  }
`;
