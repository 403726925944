import React, { FC, useMemo, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { hasConnectedToDiscussion } from 'functions/lib/utils/hasConnectedToDiscussion';
import { getConnectedSeatingOrder } from 'functions/lib/utils/getConnectedSeatingOrder';
import { isParticipantKicked } from 'functions/lib/utils/isParticipantKicked';
import { RadialLayout } from '../RadialLayout';
import HighlightArrow from './HighlightArrow';
import ParticipantCircle from './ParticipantCircle';
import CenterCircle, { CenterOuterBorder } from './CenterCircle';
import Rim from './Rim';
import LocalMuteControl from './LocalMuteControl';
import RaiseHandButton from './RaiseHandButton';
import DiscussionPrompt, { DiscussionPromptTab } from './DiscussionPrompt';
import ProfileMenu from './ProfileMenu';
import HostNameLabel from './HostNameLabel';
import CircleKindControl from './CircleKindControl';
import CornerControls from './CornerControls';
import ParticipantCountLabel from './ParticipantCountLabel';
import { dimensions } from './constants';
import { useDiscussionContext } from './DiscussionContext';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { DiscussionParticipant } from '../../../functions/lib/types/DiscussionParticipant';
import PromptModal from '../PromptModal';
import DiscussionTitle from './DiscussionTitle';
import NewMessageToast from './NewMessageToast';

export { default as ConfirmKickModal } from './ConfirmKickModal';
export { default as ConfirmPassStickModal } from './ConfirmPassStickModal';
export { default as ConfirmEndDiscussionModal } from './ConfirmEndDiscussionModal';
export { default as ConfirmLeaveDiscussionModal } from './ConfirmLeaveDiscussionModal';

interface DiscussionLayoutProps {
  radius?: number;
}

const DiscussionLayout: FC<DiscussionLayoutProps> = ({ radius = dimensions.DEFAULT_LAYOUT_RADIUS, ...rest }) => {
  const {
    user,
    discussion,
    messages,
    setName,
    forcePassStick,
    setActiveChatUserId,
    setDiscussionPromptOpen,
    setDiscussionPromptTab,
  } = useDiscussionContext();
  const [showNameInput, setShowNameInput] = useState(false);
  const userId = user.uid;
  const { participants, participantSeatingOrder, connectedParticipantIds, stickHolderId } = discussion;
  const participantArray = useMemo(() => Object.values(participants), [participants]);
  const connectedParticipantSeatingOrder = useMemo(
    () => getConnectedSeatingOrder({ participantSeatingOrder, connectedParticipantIds }),
    [participantSeatingOrder, connectedParticipantIds]
  );
  const localParticipant: DiscussionParticipant | undefined = participants[userId];
  const [selectedParticipantId, setSelectedParticipantId] = useState<string | null>(null); // Participant to show name tag for.
  const selectedParticipant = selectedParticipantId ? participants[selectedParticipantId] : null;
  const hasLocalUserJoined = hasConnectedToDiscussion(discussion, userId);
  const hasDiscussionStarted = !!discussion.startedAt;
  const isLocalUserHost = isDiscussionHost(discussion, userId);
  const isLocalUserKicked = isParticipantKicked(discussion, userId);
  const firstUnviewedMessageUserId = messages.find(message => message.viewed === false && message.toUserId === userId)
    ?.fromUserId;
  const isReady = hasLocalUserJoined && hasDiscussionStarted && !isLocalUserKicked;
  const title = discussion?.name ?? 'Listening Circles';

  const handleParticipantClick = useCallback(
    async (participantId: string) => {
      if (isLocalUserHost && stickHolderId === participantId) {
        await forcePassStick();
      } else {
        setSelectedParticipantId(value => (value === participantId ? null : participantId));
      }
    },
    [stickHolderId, isLocalUserHost, forcePassStick]
  );

  useEffect(() => {
    if (hasDiscussionStarted && localParticipant && localParticipant.isGuest && localParticipant.name === '') {
      setShowNameInput(true);
    }
  }, [localParticipant, hasDiscussionStarted]);

  if (!isReady) {
    return (
      <div {...rest}>
        <DiscussionTitle>{title}</DiscussionTitle>
        <RadialLayout radius={radius}>
          <CenterOuterBorder />
          <Rim />
          <CenterCircle layoutRadius={radius} />
        </RadialLayout>
      </div>
    );
  }

  return (
    <div {...rest}>
      <DiscussionTitle>{title}</DiscussionTitle>
      <HostNameLabel />
      <DiscussionPrompt hasUnviewedMessage={!!firstUnviewedMessageUserId} />
      <ProfileMenu />
      <ParticipantCountLabel />
      <CircleKindControl />
      <CornerControls />
      <RaiseHandButton />
      <RadialLayout radius={radius}>
        <CenterOuterBorder />
        <HighlightArrow layoutRadius={radius} seatingOrder={connectedParticipantSeatingOrder} />
        <Rim />
        <CenterCircle layoutRadius={radius} />
        <LocalMuteControl />
        {participantArray.map(participant => (
          <ParticipantCircle
            key={participant.id}
            seatingOrder={connectedParticipantSeatingOrder}
            participant={participant}
            layoutRadius={radius}
            expanded={selectedParticipant ? selectedParticipant.id === participant.id : false}
            onClick={handleParticipantClick}
          />
        ))}
      </RadialLayout>
      {showNameInput && (
        <PromptModal
          required
          title="What is your name?"
          onSubmit={async name => {
            if (!name || name.trim().length === 0) {
              throw new Error('Your name cannot be blank.');
            }
            if (name) {
              await setName(name);
            }
            setShowNameInput(false);
          }}
        />
      )}
      {firstUnviewedMessageUserId && (
        <NewMessageToast
          onClick={() => {
            setActiveChatUserId(firstUnviewedMessageUserId);
            setDiscussionPromptTab(DiscussionPromptTab.CHAT);
            setDiscussionPromptOpen(true);
          }}
        />
      )}
    </div>
  );
};

export default styled(DiscussionLayout)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
`;
