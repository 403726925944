import React, { FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Discussion } from 'functions/lib/types/Discussion';
import DiscussionListItem from './DiscussionListItem';
import { sortByDate } from '../../utils/sortByDate';
import BlockList from '../BlockList';

interface DiscussionListProps {
  discussions: Pick<Discussion, 'id' | 'name' | 'createdAt'>[];
  onDeleteDiscussion?(discussionId: string): unknown;
  onBeginDiscussion?(discussionId: string): unknown;
  onEditDiscussion?(discussionId: string): unknown;
}

const DiscussionList: FC<DiscussionListProps> = ({
  discussions,
  onDeleteDiscussion,
  onBeginDiscussion,
  onEditDiscussion,
  ...rest
}) => {
  const sortedDiscussions = useMemo(() => [...discussions].sort((a, b) => sortByDate(a.createdAt, b.createdAt, true)), [
    discussions,
  ]);

  return (
    <BlockList {...rest}>
      {sortedDiscussions.map(discussion => (
        <DiscussionListItem
          key={discussion.id}
          discussion={discussion}
          onDelete={onDeleteDiscussion}
          onBegin={onBeginDiscussion}
          onEdit={onEditDiscussion}
        />
      ))}
    </BlockList>
  );
};
export default styled(DiscussionList)``;
