import React, { FC } from 'react';
import styled from 'styled-components/macro';
import BaseModal, { Props as BaseModalProps } from '@emberex/components/lib/Modal';
import { IoIosClose as XIcon } from 'react-icons/io';

export interface ModalProps extends BaseModalProps {
  onClose(): unknown;
  required?: boolean;
}

const Modal: FC<ModalProps> = ({ onClose, required, children, ...rest }) => (
  <BaseModal {...rest} onOverlayClick={required ? undefined : onClose}>
    {!required && (
      <CloseButton onClick={onClose}>
        <XIcon />
      </CloseButton>
    )}
    {children}
  </BaseModal>
);

export default styled(Modal)`
  border-radius: 1rem;
  width: 800px;
  overflow: hidden;
  flex-shrink: 0;
`;

const CloseButton = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: auto;
  height: auto;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  font-size: 2rem;
  border: none;
  background-color: transparent;
  color: black;
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: #330000;
  }
`;
