import React, { FC, ReactNode, FormEvent, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import BaseForm from '@emberex/components/lib/Form';
import Modal, { ModalProps } from './Modal';
import PillButton from './PillButton';
import Spinner from './Spinner';
import TextInput from './TextInput';

interface PromptModalProps extends Omit<ModalProps, 'onClose'> {
  title: ReactNode;
  required?: boolean;
  buttonText?: ReactNode;
  spinnerMessage?: string;
  onSubmit(value: string | null): unknown;
}

const PromptModal: FC<PromptModalProps> = ({
  onSubmit,
  title,
  required,
  spinnerMessage,
  buttonText = 'Submit',
  ...rest
}) => {
  const [value, setValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);

  const handleInputChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await onSubmit(value.trim());
      setSubmitError(null);
    } catch (error) {
      setSubmitError(error);
    } finally {
      setSubmitting(false);
    }
  }, [onSubmit, value]);

  const handleClose = useCallback(() => {
    onSubmit(null);
  }, [onSubmit]);

  return (
    <Modal {...rest} onClose={handleClose} required={required}>
      <Form onSubmit={handleSubmit}>
        <Title>{title}</Title>
        <ErrorText visible={!!submitError}>{submitError?.message}</ErrorText>
        <TextInput label="" value={value} onChange={handleInputChange} autofocus />
        <SubmitButton>{buttonText}</SubmitButton>
      </Form>
      {submitting && <Spinner message={spinnerMessage} />}
    </Modal>
  );
};

export default styled(PromptModal)`
  padding: 4rem 10rem 8rem 10rem;
`;

const Form = styled(BaseForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorText = styled('p')<{ visible: boolean }>`
  background-color: rgba(160, 0, 0, 0.75);
  min-height: 2.25rem;
  box-sizing: border-box;
  flex-shrink: 0;
  color: white;
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 1rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

const Title = styled('h1')`
  color: rgba(51, 51, 51, 0.98);
  font-size: 2rem;
  text-align: center;
  margin: 0 0 1rem 0;
  font-weight: normal;
`;

const SubmitButton = styled(PillButton)`
  width: 20rem;
  margin-top: 4.25rem;
`;
