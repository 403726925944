import React, { FC, ReactNode, createContext, useCallback } from 'react';
import * as Twilio from 'twilio-video';
import { Callback, ErrorCallback } from '../../types';
import { SelectedParticipantProvider } from './useSelectedParticipant';
import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './useHandleOnDisconnect';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks';
import useTwilioRoom from './useTwilioRoom';

/*
 *  The hooks used by the AudioProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'AudioProvider/' directory are intended to be used by the AudioProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface AudioContextValue {
  twilioRoom: Twilio.Room | null;
  localTracks: Twilio.LocalAudioTrack[];
  isConnecting: boolean;
  isConnected: boolean;
  micPermissionGranted: boolean;
  micPermissionRequested: boolean;
  connect: (token: string) => Promise<void>;
  disconnect: () => Promise<void>;
  onError: ErrorCallback;
  onDisconnect: Callback;
  getLocalAudioTrack: () => Promise<Twilio.LocalAudioTrack | undefined>;
}

export const AudioContext = createContext<AudioContextValue>(null!);

interface AudioProviderProps {
  options?: Twilio.ConnectOptions;
  onError: ErrorCallback;
  onDisconnect?: Callback;
  children: ReactNode;
}

export const AudioProvider: FC<AudioProviderProps> = ({
  options,
  children,
  onError = () => {},
  onDisconnect = () => {},
}) => {
  const onErrorCallback = useCallback(
    (error: Twilio.TwilioError) => {
      console.log(`Twilio error: ${error.message}`, error);
      onError(error);
    },
    [onError]
  );
  const { localTracks, micPermissionGranted, micPermissionRequested, getLocalAudioTrack } = useLocalTracks();
  const { twilioRoom, isConnecting, isConnected, connect, disconnect } = useTwilioRoom(
    localTracks,
    onErrorCallback,
    options
  );

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(twilioRoom, onError);
  useHandleTrackPublicationFailed(twilioRoom, onError);
  useHandleOnDisconnect(twilioRoom, onDisconnect);

  return (
    <AudioContext.Provider
      value={{
        twilioRoom,
        localTracks,
        isConnecting,
        isConnected,
        micPermissionGranted,
        micPermissionRequested,
        onError: onErrorCallback,
        onDisconnect,
        getLocalAudioTrack,
        connect,
        disconnect,
      }}
    >
      <SelectedParticipantProvider twilioRoom={twilioRoom}>{children}</SelectedParticipantProvider>
    </AudioContext.Provider>
  );
};
