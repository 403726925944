import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';
import { Discussion } from 'functions/lib/types/Discussion';
import { SmallPillButton } from '../PillButton';
import BlockListItem from '../BlockListItem';
import { formatDate } from '../../utils/formatDate';

const origin = window.location.origin;

interface DiscussionListItemProps {
  discussion: Pick<Discussion, 'id' | 'name' | 'createdAt'>;
  expanded?: boolean;
  onDelete?(discussionId: string): unknown;
  onEdit?(discussionId: string): unknown;
  onBegin?(discussionId: string): unknown;
}

const DiscussionListItem: FC<DiscussionListItemProps> = ({ discussion, onDelete, onEdit, onBegin, ...rest }) => {
  const discussionId = discussion.id;
  const discussionPath = `discussion/${discussionId}`;
  const discussionUrl = `${origin}/${discussionPath}`;

  const handleBeginClick = useCallback(() => {
    if (onBegin) {
      onBegin(discussionId);
    }
  }, [discussionId, onBegin]);

  const handleDeleteClick = useCallback(() => {
    if (onDelete) {
      onDelete(discussionId);
    }
  }, [discussionId, onDelete]);

  const handleEditClick = useCallback(() => {
    if (onEdit) {
      onEdit(discussionId);
    }
  }, [discussionId, onEdit]);

  return (
    <BlockListItem
      {...rest}
      onDelete={handleDeleteClick}
      onEdit={handleEditClick}
      header={
        <>
          <DiscussionNameText>{discussion.name}</DiscussionNameText>
          <TimestampText>{formatDate(discussion.createdAt)}</TimestampText>
        </>
      }
      timestamp={discussion.createdAt}
    >
      <UrlRow onClick={event => event.stopPropagation()}>
        <UrlLabel>url</UrlLabel>
        <Url>{discussionUrl}</Url>
      </UrlRow>
      <BeginButton onClick={handleBeginClick}>begin</BeginButton>
    </BlockListItem>
  );
};

export default styled(DiscussionListItem)``;

const UrlRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
`;

const UrlLabel = styled('div')`
  font-weight: bold;
  margin-right: 0.5rem;
  color: #c92db4;
`;

const Url = styled('div')``;

const BeginButton = styled(SmallPillButton)`
  border: 5px solid #1e3da2;
  background-color: #fff;
  color: #1c0678;
  transition: border-color 0.2s, color 0.2s;

  &:hover {
    background-color: #fff;
    border-color: #244cc1;
    color: #244cc1;
  }
`;

const DiscussionNameText = styled('h3')`
  font-size: 1rem;
  margin: 0 0 0 0;
  font-weight: inherit;
  font-weight: bold;
`;

const TimestampText = styled('div')`
  font-size: 1rem;
  flex-shrink: 0;
  margin-left: 2rem;
  padding-right: 0.25rem;
`;
