import React, { FC } from 'react';
import styled from 'styled-components/macro';
import DiscussionModal, { DiscussionModalProps, DiscussionModalValue } from './DiscussionModal';

export type EditDiscussionModalValue = DiscussionModalValue;

type EditDiscussionModalProps = Omit<DiscussionModalProps, 'title' | 'spinnerText' | 'submitButtonText'>;

const EditDiscussionModal: FC<EditDiscussionModalProps> = props => (
  <DiscussionModal {...props} title="Edit a Circle" spinnerText="Saving Circle" submitButtonText="Save" />
);

export default styled(EditDiscussionModal)``;
