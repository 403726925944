import React, { createContext, useContext, useState, useEffect } from 'react';
import * as Twilio from 'twilio-video';

type selectedParticipantContextType = [Twilio.Participant | null, (participant: Twilio.Participant) => void];

export const selectedParticipantContext = createContext<selectedParticipantContextType>(null!);

export default function useSelectedParticipant() {
  const [selectedParticipant, setSelectedParticipant] = useContext(selectedParticipantContext);
  return [selectedParticipant, setSelectedParticipant] as const;
}

type SelectedParticipantProviderProps = {
  twilioRoom: Twilio.Room | null;
  children: React.ReactNode;
};

export function SelectedParticipantProvider({ twilioRoom, children }: SelectedParticipantProviderProps) {
  const [selectedParticipant, _setSelectedParticipant] = useState<Twilio.Participant | null>(null);
  const setSelectedParticipant = (participant: Twilio.Participant) =>
    _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : participant));

  useEffect(() => {
    if (!twilioRoom) {
      return;
    }
    const onDisconnect = () => _setSelectedParticipant(null);
    twilioRoom.on('disconnected', onDisconnect);
    return () => {
      twilioRoom.off('disconnected', onDisconnect);
    };
  }, [twilioRoom]);

  return (
    <selectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>
      {children}
    </selectedParticipantContext.Provider>
  );
}
