import React, { FC, useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { paths } from 'functions/lib/paths';
import { AppUser } from 'functions/lib/types/AppUser';
import { AppUserRole } from 'functions/lib/types/AppUserRole';
import { AppUserInvite } from 'functions/lib/types/AppUserInvite';
import { Discussion } from 'functions/lib/types/Discussion';
import { canManageUsers } from 'functions/lib/utils/canManageUsers';
import { useSecureAppState } from '../state/index';
import { getCollection, api, getDoc } from '../firebaseApp';
import useAsyncEffect from '../hooks/useAsyncEffect';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import useAudioContext from '../hooks/useAudioContext/useAudioContext';
import { AddHostModalValue } from '../components/DashboardLayout/AddHostModal';
import { EditDiscussionModalValue } from '../components/DashboardLayout/EditDiscussionModal';
import { EditHostModalValue } from '../components/DashboardLayout/EditHostModal';

const DashboardPage: FC = () => {
  const history = useHistory();
  const { connect } = useAudioContext();
  const { appUser, signOut, setError } = useSecureAppState();
  const [discussions, setDiscussions] = useState<Discussion[]>([]);
  const [userInvites, setUserInvites] = useState<AppUserInvite[]>([]);
  const [users, setUsers] = useState<AppUser[]>([]);
  const hostInvites = useMemo(() => userInvites.filter(invite => invite.role === AppUserRole.HOST), [userInvites]);
  const hosts = useMemo(() => users.filter(user => user.role === AppUserRole.HOST), [users]);

  useAsyncEffect(async () => {
    let cancelA: Function | null = null;
    let cancelB: Function | null = null;

    if (canManageUsers(appUser)) {
      cancelA = getCollection<AppUser>(paths.appUsers).onSnapshot(snapshot => {
        const newUsers = snapshot.docs.map(doc => doc.data());
        setUsers(newUsers);
      });

      cancelB = getCollection<AppUserInvite>(paths.appUserInvites).onSnapshot(snapshot => {
        const newInvites = snapshot.docs.map(doc => doc.data());
        setUserInvites(newInvites);
      });
    }

    const cancelC = getCollection<Discussion>(paths.discussions)
      .where('ownerId', '==', appUser.id)
      .onSnapshot(snapshot => {
        const newDiscussions = snapshot.docs.map(doc => doc.data());
        setDiscussions(newDiscussions);
      });

    return () => {
      if (cancelA) {
        cancelA();
      }
      if (cancelB) {
        cancelB();
      }
      cancelC();
    };
  }, [appUser]);

  const beginDiscussion = useCallback(
    async (discussionId: string) => {
      try {
        const twilioJwt = await api.joinDiscussion({ discussionId, name: appUser.displayName });
        await connect(twilioJwt);
        history.push(`/discussion/${discussionId}`, { preJoined: true });
      } catch (error) {
        setError(error);
      }
    },
    [connect, setError, appUser, history]
  );

  const inviteHost = useCallback(async (value: AddHostModalValue) => {
    await api.inviteUser({ ...value, role: AppUserRole.HOST });
  }, []);

  const deleteHost = useCallback(async (userId: string) => {
    await getDoc(paths.appUser(userId)).update('role', AppUserRole.PARTICIPANT);
  }, []);

  const deleteInvite = useCallback(async (inviteId: string) => {
    await getDoc(paths.appUserInvite(inviteId)).update('role', AppUserRole.PARTICIPANT);
  }, []);

  const deleteDiscussion = useCallback(async (discussionId: string) => {
    await getDoc(paths.discussion(discussionId)).delete();
  }, []);

  const editDiscussion = useCallback(async (discussionId: string, fields: EditDiscussionModalValue) => {
    await api.updateDiscussion({
      discussionId,
      ...fields,
    });
  }, []);

  const editUser = useCallback(async (userId: string, fields: EditHostModalValue) => {
    await api.updateUser({
      userId,
      ...fields,
    });
  }, []);

  const editInvite = useCallback(async (inviteId: string, fields: EditHostModalValue) => {
    await api.updateInvite({
      inviteId,
      ...fields,
    });
  }, []);

  return (
    <DashboardLayout
      user={appUser}
      discussions={discussions}
      hosts={hosts}
      hostInvites={hostInvites}
      onCreateDiscussion={api.createDiscussion}
      onEditDiscussion={editDiscussion}
      onDeleteDiscussion={deleteDiscussion}
      onBeginDiscussion={beginDiscussion}
      onInviteHost={inviteHost}
      onDeleteHost={deleteHost}
      onEditHost={editUser}
      onEditInvite={editInvite}
      onDeleteInvite={deleteInvite}
      onLogout={signOut}
    />
  );
};

export default DashboardPage;
