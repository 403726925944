import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { MicIcon } from '../icons';

interface MuteButtonProps {
  muted: boolean;
  backgroundColor: string;
  iconColor: string;
  visible: boolean;
  disabled?: boolean;
  faded?: boolean;
  onChange(muted: boolean): unknown;
}

const MuteButton: FC<MuteButtonProps> = ({ onChange, ...rest }) => {
  return (
    <Root {...rest} onClick={() => onChange(!rest.muted)}>
      <MicIcon />
    </Root>
  );
};

export default styled(MuteButton)``;

const Root = styled('button')<Omit<MuteButtonProps, 'onChange'>>`
  box-sizing: border-box;
  position: relative;
  border: none;
  padding: 0.25rem 0 0 0;
  height: 2rem;
  width: 2rem;
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  font-size: 1.1rem;
  transition: opacity 0.2s, background-color 0.2s;
  color: ${props => props.iconColor};
  opacity: ${props => {
    if (!props.visible) {
      return 0;
    }
    if (props.faded) {
      return 0.2;
    }
    return 1;
  }};

  &:after {
    content: '';
    display: ${props => (props.muted ? 'block' : 'none')};
    left: 50%;
    top: 50%;
    position: absolute;
    width: 80%;
    height: 2px;
    border-bottom: 2px solid ${props => props.iconColor};
    transition: background-color 0.2s;
    background-color: ${props => props.backgroundColor};
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;
