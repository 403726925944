import React, { FC, ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import Modal, { ModalProps } from './Modal';
import PillButton from './PillButton';
import Spinner from './Spinner';

export interface ConfirmModalProps extends ModalProps {
  title: ReactNode;
  message?: ReactNode;
  buttonLabel?: ReactNode;
  spinnerMessage?: string;
  onConfirm(): unknown;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  onConfirm,
  onClose,
  title,
  spinnerMessage,
  message = 'This action cannot be undone.',
  buttonLabel,
  ...rest
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleConfirm = useCallback(async () => {
    try {
      setSubmitting(true);
      await onConfirm();
    } finally {
      setSubmitting(false);
    }
  }, [onConfirm]);

  return (
    <Modal {...rest} onOverlayClick={onClose} onClose={onClose}>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <ConfirmButton onClick={handleConfirm}>{buttonLabel}</ConfirmButton>
      {submitting && <Spinner message={spinnerMessage} />}
    </Modal>
  );
};

export default styled(ConfirmModal)`
  text-align: center;
  align-items: center;
  padding-top: 9rem;
  padding-bottom: 8rem;
`;

const Title = styled('h1')`
  margin: 0 0 2rem 0;
  font-size: 2rem;
`;

const Message = styled('p')`
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 0 2.5rem 0;
  max-width: 18rem;
`;

const ConfirmButton = styled(PillButton)`
  width: 20rem;
`;
