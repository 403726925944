import React, { FC, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { hasDiscussionStarted } from 'functions/lib/utils/hasDiscussionStarted';
import { hasConnectedToDiscussion } from 'functions/lib/utils/hasConnectedToDiscussion';
import { getPerimeterAngle, RadialLayoutChild } from '../RadialLayout';
import { useSmoothAngle } from '../../hooks/useSmoothAngle';
import { TRANSITION_SPEED, dimensions } from './constants';
import { useDiscussionContext } from './DiscussionContext';
import { getFocusedParticipantId } from 'functions/lib/utils/getFocusedParticipantId';

interface HighlightArrowProps {
  seatingOrder: string[];
  layoutRadius: number;
}

/**
 * Faded arrow that points to the current speaker during a discussion.
 */
const HighlightArrow: FC<HighlightArrowProps> = ({ seatingOrder, layoutRadius, ...rest }) => {
  const { userId, discussion } = useDiscussionContext();
  const stickHolderIsSitting = seatingOrder.includes(discussion.stickHolderId);
  const visible =
    stickHolderIsSitting && hasDiscussionStarted(discussion) && hasConnectedToDiscussion(discussion, userId);

  const angle = useSmoothAngle(
    useMemo(() => {
      const focusedParticipantId = getFocusedParticipantId(discussion);
      const focusedParticipantSeatingIndex = seatingOrder.indexOf(focusedParticipantId);

      if (focusedParticipantSeatingIndex === -1) {
        return 0;
      }

      return getPerimeterAngle(focusedParticipantSeatingIndex, seatingOrder.length);
    }, [seatingOrder, discussion])
  );
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvas) {
      return;
    }
    // The canvas should only be re-rendered when the canvas element re-mounts or changes size.
    renderHighlightArrowCanvas(canvas, layoutRadius);
  }, [canvas, layoutRadius]);

  return (
    <RadialLayoutChild {...rest}>
      <canvas
        ref={setCanvas}
        width={layoutRadius}
        height={layoutRadius * (dimensions.CENTER_CIRCLE_RADIUS * 2) - 5} //1.15}
        style={{
          opacity: visible ? 0.42 : 0,
          transform: `rotate(${angle}rad)`,
        }}
      />
    </RadialLayoutChild>
  );
};

export default styled(HighlightArrow)`
  left: 25%;
  pointer-events: none;

  > canvas {
    transition: transform ${TRANSITION_SPEED};
    transform-origin: 0 center;
  }
`;

function renderHighlightArrowCanvas(canvas: HTMLCanvasElement, layoutRadius: number): void {
  const context = canvas.getContext('2d');

  if (!context) {
    return;
  }

  const { width: canvasWidth, height: canvasHeight } = canvas;

  context.clearRect(0, 0, canvasWidth, canvasHeight);
  context.save();

  const gradient = context.createLinearGradient(0, 0, canvasWidth, 0);
  gradient.addColorStop(0, '#C92DB4');
  gradient.addColorStop(0.5, '#ACF00B');

  context.fillStyle = gradient;
  context.beginPath();
  context.moveTo(0, 0);
  context.lineTo(canvasWidth, canvasHeight / 2 - layoutRadius * 0.03);
  context.lineTo(canvasWidth, canvasHeight / 2 + layoutRadius * 0.03);
  context.lineTo(0, canvasHeight);
  context.closePath();
  context.fill();
  context.restore();
}
