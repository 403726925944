import styled from 'styled-components/macro';

const PillButton = styled('button')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  border-radius: 2rem;
  background-color: #1c0678;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #0f014a;
  }

  &:disabled {
    cursor: default;
  }
`;

export const SmallPillButton = styled(PillButton)`
  padding: 0.5rem 2rem 0.5rem 2rem;
`;

export default PillButton;
