import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactionEmoji, reactionEmojis, REACTION_EMOJI_COOLDOWN } from 'functions/lib/types/ReactionEmoji';
import ReactionEmojiImage from './ReactionEmojiImage';

interface ReactionEmojiMenuProps {
  onEmojiSelect(value: ReactionEmoji): unknown;
}

const ReactionEmojiMenu: FC<ReactionEmojiMenuProps> = ({ onEmojiSelect, ...rest }) => {
  const [disabled, setDisabled] = useState(false);

  const handleEmojiClick = useCallback(
    (emoji: ReactionEmoji) => {
      setDisabled(true);
      onEmojiSelect(emoji);
    },
    [onEmojiSelect]
  );

  // Re-enable after 10 seconds of being disabled (cooldown to prevent the user from spamming emojis)
  // This cooldown should also be checked in the backend.
  useEffect(() => {
    if (disabled) {
      const timeout = setTimeout(() => {
        setDisabled(false);
      }, REACTION_EMOJI_COOLDOWN);
      return () => clearTimeout(timeout);
    }
  }, [disabled]);

  return (
    <Root {...rest} faded={disabled}>
      {reactionEmojis.map((reactionEmoji, i) => (
        <EmojiButton key={i} disabled={disabled} onClick={() => handleEmojiClick(reactionEmoji)}>
          <ReactionEmojiImage reactionEmoji={reactionEmoji} />
        </EmojiButton>
      ))}
    </Root>
  );
};

export default styled(ReactionEmojiMenu)``;

const Root = styled('div')<{ faded?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 1.25rem;
  background-color: rgb(28, 6, 120, 0.33);
  padding: 0.25rem 0.4rem 0.25rem 0.4rem;
  transition: opacity 0.2s;
  opacity: ${props => (props.faded ? 0.5 : 1)};
`;

const EmojiButton = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  flex-shrink: 0;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  background-color: rgba(15, 1, 74, 0.4);
  transition: background-color 0.2s;

  & + & {
    margin-left: 0.75rem;
  }

  &:not(:disabled):hover {
    background-color: rgba(15, 1, 74, 0.8);
  }
`;
