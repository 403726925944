import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components/macro';
import { Switch, Route } from 'react-router-dom';
import useToggleState from '@emberex/react-utils/lib/useToggleState';
import { Discussion } from 'functions/lib/types/Discussion';
import { AppUser } from 'functions/lib/types/AppUser';
import { canManageUsers } from 'functions/lib/utils/canManageUsers';
import { findById } from 'functions/lib/utils/arrayUtils';
import { AppUserInvite } from 'functions/lib/types/AppUserInvite';
import Logo from '../Logo';
import DashboardNav from './DashboardNav';
import AddMenu from './AddMenu';
import UserMenu from './UserMenu';
import UserList from './UserList';
import DiscussionList from './DiscussionList';
import CreateDiscussionModal, { CreateDiscussionModalValue } from './CreateDiscussionModal';
import AddHostModal, { AddHostModalValue } from './AddHostModal';
import ConfirmModal from '../ConfirmModal';
import Spinner from '../Spinner';
import EditHostModal, { EditHostModalValue } from './EditHostModal';
import EditDiscussionModal, { EditDiscussionModalValue } from './EditDiscussionModal';

interface DashboardLayoutProps {
  user: AppUser;
  discussions: Pick<Discussion, 'id' | 'name' | 'allowGuests' | 'createdAt'>[];
  hosts: AppUser[];
  hostInvites: AppUserInvite[];
  onCreateDiscussion(fields: CreateDiscussionModalValue): unknown;
  onDeleteDiscussion(discussionId: string): unknown;
  onBeginDiscussion(discussionId: string): unknown;
  onEditDiscussion(discussionId: string, fields: EditDiscussionModalValue): unknown;
  onInviteHost(fields: AddHostModalValue): unknown;
  onEditHost(hostId: string, fields: EditHostModalValue): unknown;
  onEditInvite(hostId: string, fields: EditHostModalValue): unknown;
  onDeleteHost(hostId: string): unknown;
  onDeleteInvite(inviteId: string): unknown;
  onLogout(): unknown;
}

const DashboardLayout: FC<DashboardLayoutProps> = ({
  user,
  discussions,
  hosts,
  hostInvites,
  onCreateDiscussion,
  onDeleteDiscussion,
  onBeginDiscussion,
  onEditDiscussion,
  onInviteHost,
  onDeleteHost,
  onDeleteInvite,
  onEditHost,
  onEditInvite,
  onLogout,
  ...rest
}) => {
  const [loggingOut, setLoggingOut] = useState(false);
  const [beginningDiscussion, setBeginningDiscussion] = useState(false);
  const [showCreateDiscussionModal, toggleCreateDiscussionModal] = useToggleState(false);
  const [showInviteHostModal, toggleInviteHostModal] = useToggleState(false);
  const [confirmDeleteInviteId, setConfirmDeleteInviteId] = useState<string | null>(null);
  const [confirmDeleteHostId, setConfirmDeleteHostId] = useState<string | null>(null);
  const [confirmDeleteDiscussionId, setConfirmDeleteDiscussionId] = useState<string | null>(null);
  const [editHostId, setEditHostId] = useState<string | null>(null);
  const [editInviteId, setEditInviteId] = useState<string | null>(null);
  const [editDiscussionId, setEditDiscussionId] = useState<string | null>(null);
  const discussionToDelete = confirmDeleteDiscussionId ? findById(discussions, confirmDeleteDiscussionId) : null;
  const hostToDelete = confirmDeleteHostId ? findById(hosts, confirmDeleteHostId) : null;
  const inviteToDelete = confirmDeleteInviteId ? findById(hostInvites, confirmDeleteInviteId) : null;
  const editInvite = editInviteId ? hostInvites.find(invite => invite.id === editInviteId) : null;
  const editHost = editHostId ? hosts.find(host => host.id === editHostId) : null;
  const editDiscussion = editDiscussionId ? discussions.find(discussion => discussion.id === editDiscussionId) : null;

  const createDiscussion = useCallback(
    async (fields: CreateDiscussionModalValue) => {
      await onCreateDiscussion(fields);
      toggleCreateDiscussionModal();
    },
    [toggleCreateDiscussionModal, onCreateDiscussion]
  );

  const updateDiscussion = useCallback(
    async (fields: EditDiscussionModalValue) => {
      if (editDiscussionId) {
        await onEditDiscussion(editDiscussionId, fields);
      }
      setEditDiscussionId(null);
    },
    [editDiscussionId, onEditDiscussion]
  );

  const inviteHost = useCallback(
    async (fields: AddHostModalValue) => {
      await onInviteHost(fields);
      toggleInviteHostModal();
    },
    [toggleInviteHostModal, onInviteHost]
  );

  const updateHost = useCallback(
    async (fields: EditHostModalValue) => {
      if (editHostId) {
        await onEditHost(editHostId, fields);
      }
      setEditHostId(null);
    },
    [editHostId, onEditHost]
  );

  const updateHostInvite = useCallback(
    async (fields: EditHostModalValue) => {
      if (editInviteId) {
        await onEditInvite(editInviteId, fields);
      }
      setEditInviteId(null);
    },
    [editInviteId, onEditInvite]
  );

  const deleteInvite = useCallback(
    async (inviteId: string) => {
      await onDeleteInvite(inviteId);
      setConfirmDeleteInviteId(null);
    },
    [onDeleteInvite]
  );

  const deleteHost = useCallback(
    async (hostId: string) => {
      await onDeleteHost(hostId);
      setConfirmDeleteHostId(null);
    },
    [onDeleteHost]
  );

  const deleteDiscussion = useCallback(
    async (discussionId: string) => {
      await onDeleteDiscussion(discussionId);
      setConfirmDeleteDiscussionId(null);
    },
    [onDeleteDiscussion]
  );

  const beginDiscussion = useCallback(
    async (discussionId: string) => {
      try {
        setBeginningDiscussion(true);
        await onBeginDiscussion(discussionId);
      } finally {
        setBeginningDiscussion(false);
      }
    },
    [onBeginDiscussion]
  );

  const logout = useCallback(async () => {
    try {
      setLoggingOut(true);
      await onLogout();
    } finally {
      setLoggingOut(false);
    }
  }, [onLogout]);

  return (
    <div {...rest}>
      {loggingOut && <Spinner message="Logging out" />}
      {beginningDiscussion && <Spinner message="Beginning circle" />}
      {showCreateDiscussionModal && (
        <CreateDiscussionModal onClose={toggleCreateDiscussionModal} onSubmit={createDiscussion} />
      )}
      {showInviteHostModal && <AddHostModal onClose={toggleInviteHostModal} onSubmit={inviteHost} />}
      {!!hostToDelete && (
        <ConfirmModal
          title={`Delete ${hostToDelete.displayName}?`}
          buttonLabel="Delete"
          spinnerMessage="Deleting host"
          onClose={() => setConfirmDeleteHostId(null)}
          onConfirm={() => deleteHost(hostToDelete.id)}
        />
      )}
      {!!inviteToDelete && (
        <ConfirmModal
          title={`Delete ${inviteToDelete.firstName} ${inviteToDelete.lastName}?`}
          buttonLabel="Delete"
          spinnerMessage="Deleting host"
          onClose={() => setConfirmDeleteInviteId(null)}
          onConfirm={() => deleteInvite(inviteToDelete.id)}
        />
      )}
      {!!discussionToDelete && (
        <ConfirmModal
          title={`Delete ${discussionToDelete.name}?`}
          buttonLabel="Delete"
          spinnerMessage="Deleting circle"
          onClose={() => setConfirmDeleteDiscussionId(null)}
          onConfirm={() => deleteDiscussion(discussionToDelete.id)}
        />
      )}
      {!!editHost && (
        <EditHostModal
          value={{
            firstName: editHost.firstName ?? '',
            lastName: editHost.lastName ?? '',
            email: editHost.email ?? '',
          }}
          onClose={() => setEditHostId(null)}
          onSubmit={updateHost}
        />
      )}
      {!!editInvite && (
        <EditHostModal
          value={{
            firstName: editInvite.firstName ?? '',
            lastName: editInvite.lastName ?? '',
            email: editInvite.email ?? '',
          }}
          onClose={() => setEditInviteId(null)}
          onSubmit={updateHostInvite}
        />
      )}
      {!!editDiscussion && (
        <EditDiscussionModal
          value={{
            name: editDiscussion.name,
            allowGuests: editDiscussion.allowGuests,
          }}
          onClose={() => setEditDiscussionId(null)}
          onSubmit={updateDiscussion}
        />
      )}
      <Content>
        <MenuRow>
          <UserMenu onLogout={logout} username={user.displayName} />
        </MenuRow>
        <LogoRow>
          <Logo />
        </LogoRow>
        <ControlRow>
          <AddMenu
            showAddHostButton={canManageUsers(user)}
            onCreateCircle={toggleCreateDiscussionModal}
            onCreateHost={toggleInviteHostModal}
          />
          <DashboardNav showHostsLink={canManageUsers(user)} />
        </ControlRow>
        <ListWrapper>
          <Switch>
            <Route path="/hosts">
              <UserList
                users={hosts}
                userInvites={hostInvites}
                onDeleteUser={setConfirmDeleteHostId}
                onDeleteInvite={setConfirmDeleteInviteId}
                onEditInvite={setEditInviteId}
                onEditUser={setEditHostId}
              />
            </Route>
            <Route path="/">
              <DiscussionList
                discussions={discussions}
                onDeleteDiscussion={setConfirmDeleteDiscussionId}
                onBeginDiscussion={beginDiscussion}
                onEditDiscussion={setEditDiscussionId}
              />
            </Route>
          </Switch>
        </ListWrapper>
      </Content>
    </div>
  );
};

export default styled(DashboardLayout)``;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  width: 1100px;
  max-width: 100%;
  margin: 0 auto;
`;

const MenuRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 2.5rem 2rem 5.25rem 2rem;
`;

const LogoRow = styled('div')`
  padding: 0 6.25rem 2rem 6.25rem;
  flex-shrink: 0;
`;

const ControlRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  padding: 0 2.25rem 1.5rem 2.25rem;

  ${AddMenu} {
    margin-right: 1.5rem;
  }
`;

const ListWrapper = styled('div')`
  padding: 0 8rem 0 7.75rem;
`;
