import { padZero } from './padZero';
import { parseDate } from './parseDate';

export function formatDate(date: unknown): string {
  const dateObject = parseDate(date);
  if (!dateObject) {
    return '';
  }
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();

  return `${padZero(month)} / ${padZero(day)} / ${year}`;
}
