import React, { FC, ReactNode, ComponentPropsWithoutRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';

interface TextInputProps extends ComponentPropsWithoutRef<'input'> {
  label?: ReactNode;
  autofocus?: boolean;
}

const TextInput: FC<TextInputProps> = ({ label, className, style, autofocus, ...rest }) => {
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);

  const focusInput = useCallback(() => {
    if (inputEl) {
      inputEl.focus();
    }
  }, [inputEl]);

  useEffect(() => {
    if (autofocus && inputEl) {
      inputEl.focus();
    }
  }, [inputEl, autofocus]);

  return (
    <div className={className} style={style}>
      <Box onClick={focusInput}>
        {label ? <Label>{label}</Label> : null}
        <Input {...rest} ref={setInputEl} />
      </Box>
    </div>
  );
};

export default styled(TextInput)`
  border-bottom: 2px solid #2989ff;
  padding-bottom: 0.5rem;
  flex-shrink: 0;

  & + & {
    margin-top: 1.25rem;
  }
`;

const Box = styled('div')`
  border-radius: 0.5rem;
  background-color: #f2f2f2;
  color: black;
  justify-content: center;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  display: flex;
  flex-direction: column;
`;

const Label = styled('label')`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

const Input = styled('input')`
  color: black;
  font-size: 1.25rem;
  border: none;
  background-color: transparent;
  max-width: none;
  width: 100%;
`;
