import React, { FC, createContext, useContext } from 'react';
import { Discussion } from 'functions/lib/types/Discussion';
import { SpeakingMode } from 'functions/lib/types/SpeakingMode';
import { ReactionEmoji } from 'functions/lib/types/ReactionEmoji';
import { AppUser } from 'functions/lib/types/AppUser';
import { User } from '../../state/User';
import { DiscussionMessage } from 'functions/src/types/DiscussionMessage';
import { DiscussionPromptTab } from './DiscussionPrompt';

export interface DiscussionState {
  userId: string;
  user: User;
  appUser: AppUser;
  discussion: Discussion;
  messages: DiscussionMessage[];
  muted: boolean;
  activeChatUserId: string | null;
  setActiveChatUserId(activeChatUserId: string | null): unknown;
  discussionPromptTab: DiscussionPromptTab;
  setDiscussionPromptTab(discussionPromptTab: DiscussionPromptTab): unknown;
  discussionPromptOpen: boolean;
  setDiscussionPromptOpen(discussionPromptOpen: boolean): unknown;

  joiningDiscussion: boolean;
  joinDiscussion(): unknown;

  passingStick: boolean;
  passStick(): unknown;
  forcePassStick(): unknown;

  takingStick: boolean;
  takeStick(options: { passImmediately: boolean; passEmoji: string | null; passMessage: string | null }): unknown;

  raisingHand: boolean;
  raiseHand(): unknown;

  loweringHand: boolean;
  lowerHand(): unknown;

  swappingParticipants: boolean;
  swapParticipants(participant1Id: string, participant2Id: string): unknown;

  removingParticipant: boolean;
  removeParticipant(participantId: string): unknown;

  settingPrompt: boolean;
  setPrompt(prompt: string): unknown;

  settingNotes: boolean;
  setNotes(notes: string): unknown;

  settingCircleKind: boolean;
  setSpeakingMode(speakingMode: SpeakingMode): unknown;

  endingDiscussion: boolean;
  endDiscussion(): unknown;

  togglingAllChat: boolean;
  toggleAllChat(): unknown;

  settingProfilePicture: boolean;
  removingProfilePicture: boolean;
  setProfilePicture(file: File | null): unknown;

  settingName: boolean;
  setName(name: string): unknown;

  settingReactionEmoji: boolean;
  setReactionEmoji(reactionEmoji: ReactionEmoji): unknown;

  toggleNameTags(): unknown;
  showingNameTags: boolean;

  shuffleParticipants(): unknown;
  shufflingParticipants: boolean;

  sendMessage(props: { toUserId: string; content: string }): unknown;
  sendingMessage: boolean;

  setStickReceiverTyping(typing: boolean): unknown;

  markMessagesViewed(fromUserId: string): unknown;

  toggleMuted(): unknown;
  leave(): unknown;
}

const DiscussionContext = createContext<DiscussionState | null>(null);

export const DiscussionContextProvider: FC<{ value: DiscussionState }> = ({ children, value }) => (
  <DiscussionContext.Provider value={value}>{children}</DiscussionContext.Provider>
);

export function useDiscussionContext(): DiscussionState {
  const discussionState = useContext(DiscussionContext);

  if (!discussionState) {
    throw new Error('useDiscussionContext can only be called beneath DiscussionContextProvider in the render tree.');
  }

  return discussionState;
}
