import React, { FC, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import styled from 'styled-components/macro';
import { ChevronLeftIcon } from '../icons';
import { useDiscussionContext } from './DiscussionContext';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { DiscussionParticipant } from 'functions/src/types/DiscussionParticipant';
import useAsyncEffect from '../../hooks/useAsyncEffect';

interface DiscussionChatProps {
  participant: DiscussionParticipant;
  onBack(): unknown;
}

const DiscussionChat: FC<DiscussionChatProps> = ({ onBack, participant, ...rest }) => {
  const { userId, discussion, messages, sendMessage, markMessagesViewed } = useDiscussionContext();
  const { id: participantId } = participant;
  const messageListRef = useRef<HTMLUListElement | null>(null);
  const isLocalUserHost = isDiscussionHost(discussion, userId);
  const [pendingMessage, setPendingMessage] = useState('');
  const activeChatMessages = useMemo(
    () => messages.filter(message => message.fromUserId === participantId || message.toUserId === participantId),
    [messages, participantId]
  );
  const unviewedRecievedMessageCount = useMemo(
    () => activeChatMessages.filter(message => message.toUserId === userId && !message.viewed).length,
    [activeChatMessages, userId]
  );

  /**
   * Auto-scroll messages into view as they're received.
   */
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo({ top: messageListRef.current.scrollHeight });
    }
  }, [activeChatMessages]);

  /**
   * Mark messages as viewed as they're received (debounced).
   */
  useEffect(() => {
    if (unviewedRecievedMessageCount > 0) {
      const timeout = setTimeout(async () => {
        await markMessagesViewed(participantId);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [markMessagesViewed, unviewedRecievedMessageCount, participantId]);

  /**
   * Immediately mark all visible unviewed messages as viewed.
   */
  useAsyncEffect(async () => {
    if (unviewedRecievedMessageCount > 0) {
      await markMessagesViewed(participantId);
    }
  }, []);

  const sendPendingMessage = useCallback(async () => {
    setPendingMessage('');
    await sendMessage({ toUserId: participantId, content: pendingMessage });
  }, [sendMessage, participantId, pendingMessage]);

  // User has selected someone to talk to (this defaults to the host as a participant)
  return (
    <div {...rest}>
      <MessageForm onSubmit={sendPendingMessage}>
        {isLocalUserHost && (
          <MessageBackButton onClick={onBack}>
            <ChevronLeftIcon /> back to messages
          </MessageBackButton>
        )}
        <MessageList
          ref={el => {
            if (el) {
              messageListRef.current = el;
              // When the chat mounts, scroll to the most recent messages
              el.scrollTo({ top: el.scrollHeight });
            }
          }}
          height={isLocalUserHost ? '7.75rem' : '9rem'}
        >
          {activeChatMessages.length === 0 && (
            <li>
              <NoMessagesPlaceholder>
                What message would you like to send to {isLocalUserHost ? participant.name : 'the host'}?
              </NoMessagesPlaceholder>
            </li>
          )}
          {activeChatMessages.map(message => (
            <li key={message.id}>
              <MessageUserName color={message.fromUserId === userId ? 'black' : 'white'}>
                {message.fromUserName}:{' '}
              </MessageUserName>
              <pre>{message.content}</pre>
            </li>
          ))}
        </MessageList>

        <MessageInput
          placeholder="Enter your message here..."
          value={pendingMessage}
          onChange={event => setPendingMessage(event.currentTarget.value)}
          onKeyDown={event => {
            // Send the message when enter is pressed unless the shift key is also pressed
            if (event.key === 'Enter' && !event.shiftKey) {
              sendPendingMessage();
              event.preventDefault();
            }
          }}
        />
      </MessageForm>
    </div>
  );
};

export default styled(DiscussionChat)``;

const MessageForm = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem 1rem 1rem;
  flex: 1 1 auto;
`;

const MessageUserName = styled('span')<{ color: string }>`
  color: ${props => props.color};
  font-weight: bold;
`;

const MessageInput = styled('textarea')`
  width: 100%;
  height: 3.375rem;
  flex-shrink: 0;
  overflow-y: auto;
  box-sizing: border-box;
  max-width: none;
  resize: none;
  background-color: transparent;
  border: 2px solid #0f014a;
  border-radius: 1rem;
  color: white;
  font-family: inherit;
  font-size: 1rem;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  margin: 0 0 0 0;

  &::placeholder {
    color: white;
    opacity: 1;
    font-style: italic;
  }
`;

const MessageList = styled('ul')<{ height: string }>`
  overflow: auto;
  width: 100%;
  height: ${props => props.height};
  list-style: none;
  padding: 0 0 0 0;
  flex-shrink: 0;
  margin: 0.75rem 0 1rem 0;

  pre {
    display: inline;
    font-family: inherit;
    font-size: inherit;
    white-space: pre-wrap;
    word-break: break-word;
  }

  li {
    margin: 0 0 0 0;
    width: 100%;
  }

  li + li {
    margin-top: 1.25rem;
  }
`;

const MessageBackButton = styled('button')`
  background-color: transparent;
  border: none;
  padding: 0 0 0 0;
  font-style: italic;
  font-size: 0.75rem;
  color: #0f014a;
  height: 1rem;
  width: 9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;

  svg {
    font-size: 1.25rem;
  }
`;

const NoMessagesPlaceholder = styled('p')`
  margin: 0 0 0 0;
  font-style: italic;
`;
