import React, { FC, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import DotLoaderSpinner from 'react-spinners/DotLoader';

interface SpinnerProps {
  message?: ReactNode;
  color?: string;
  backgroundColor?: string;
}

const Spinner: FC<SpinnerProps> = ({ message, color = '#1C0678', backgroundColor = '#FFFFFF', ...rest }) => {
  return (
    <Root {...rest} backgroundColor={backgroundColor}>
      <Content>
        <DotLoaderSpinner color={color} />
        {message ? <Message color={color}>{message}</Message> : null}
      </Content>
    </Root>
  );
};

export default styled(Spinner)``;

const Root = styled('div')<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  animation-name: ${keyframes`
      from {
        opacity: 0;
      } to {
        opacity: 1;
      }
    `};
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
`;

const Message = styled('div')<{ color: string }>`
  margin-top: 3rem;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.color};
`;
