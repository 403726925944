import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface LogoProps {
  lineBreak?: boolean;
}

const Logo: FC<LogoProps> = ({ lineBreak = true, ...rest }) => {
  return (
    <div {...rest}>
      <h1>Listening{lineBreak ? <br /> : ' '}Circles</h1>
    </div>
  );
};

export default styled(Logo)`
  flex-shrink: 0;
  h1 {
    color: #2989ff;
    font-size: 2.5rem;
    line-height: 2.4rem;
    font-weight: normal;
    margin: 0 0 0 0;
  }
`;
