export function parseDate(date: unknown): Date | null {
  if (date === null) {
    return null;
  }
  if (date instanceof Date) {
    return date;
  }
  if (typeof date === 'number' || typeof date === 'string') {
    return new Date(date);
  }
  if (date && typeof (date as any).toDate === 'function') {
    return (date as any).toDate();
  }
  throw new Error(`Invalid date: ${date}`);
}
