import { useEffect, useState } from 'react';
import { getDoc } from '../firebaseApp';

export function useDoc<T>(path: string | null): [T | null, boolean] {
  const [state, setState] = useState<[T | null, boolean]>([null, false]);

  useEffect(() => {
    if (path) {
      const cancel = getDoc(path).onSnapshot(snapshot => {
        const data = snapshot.data();
        setState([data as T, true]);
      });
      return cancel;
    } else {
      setState([null, false]);
    }
  }, [path]);

  return state;
}
