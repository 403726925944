import { useRef } from 'react';
import { getShortestAngleDifference } from '../utils/trigUtils';

/**
 * Hook for keeping track of rotational changes to ensure angle changes take the shortest path.
 * Keeps things from spinning wildly when they go from 330deg to 0deg for example.
 */
export function useSmoothAngle(angle: number): number {
  const angleRef = useRef(angle);

  if (angle !== angleRef.current) {
    const diff = getShortestAngleDifference(angle, angleRef.current);
    angleRef.current += diff;
  }

  return angleRef.current;
}
