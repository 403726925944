import styled from 'styled-components/macro';

const DiscussionTitle = styled('div')`
  color: white;
  font-size: 2rem;
  letter-spacing: 0;
  line-height: 38px;
  position: absolute;
  font-weight: 400;
  left: 2rem;
  top: 1rem;
`;

export default DiscussionTitle;
