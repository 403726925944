import React, { FC, FormEvent, ReactNode, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import BaseForm from '@emberex/components/lib/Form';
import Modal, { ModalProps } from '../Modal';
import PillButton from '../PillButton';
import Spinner from '../Spinner';
import TextInput from '../TextInput';

export interface DiscussionModalValue {
  name: string;
  allowGuests: boolean;
}

export interface DiscussionModalProps extends ModalProps {
  title: ReactNode;
  spinnerText: string;
  submitButtonText: string;
  value: DiscussionModalValue;
  onSubmit(value: DiscussionModalValue): unknown;
}

const DiscussionModal: FC<DiscussionModalProps> = ({
  title,
  spinnerText,
  submitButtonText,
  value,
  onSubmit,
  ...rest
}) => {
  const [name, setName] = useState(value.name);
  const [allowGuests, setAllowGuests] = useState(value.allowGuests);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<Error | null>(null);

  const handleNameChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setName(event.currentTarget.value);
  }, []);

  const handleAllowGuestsChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setAllowGuests(event.currentTarget.checked);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await onSubmit({ name, allowGuests });
      setSubmitError(null);
    } catch (error) {
      setSubmitError(error);
    } finally {
      setSubmitting(false);
    }
  }, [onSubmit, name, allowGuests]);

  return (
    <Modal {...rest}>
      <Form onSubmit={handleSubmit}>
        <Title>{title}</Title>
        <ErrorText visible={!!submitError}>{submitError?.message}</ErrorText>
        <TextInput label="Circle Name" value={name} onChange={handleNameChange} autofocus />
        <AllowGuestsLabel>
          Allow guests <input type="checkbox" onChange={handleAllowGuestsChange} checked={allowGuests} />
        </AllowGuestsLabel>
        <SubmitButton>{submitButtonText}</SubmitButton>
      </Form>
      {submitting && <Spinner message={spinnerText} />}
    </Modal>
  );
};

export default styled(DiscussionModal)`
  padding: 4rem 10rem 8rem 10rem;
`;

const Form = styled(BaseForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorText = styled('p')<{ visible: boolean }>`
  background-color: rgba(160, 0, 0, 0.75);
  min-height: 2.25rem;
  box-sizing: border-box;
  flex-shrink: 0;
  color: white;
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 1rem;
  margin: 0 0 1rem 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

const Title = styled('h1')`
  color: rgba(51, 51, 51, 0.98);
  font-size: 2rem;
  text-align: center;
  margin: 0 0 1rem 0;
  font-weight: normal;
`;

const SubmitButton = styled(PillButton)`
  width: 20rem;
  margin-top: 4.25rem;
`;

const AllowGuestsLabel = styled('label')`
  margin-top: 2rem;
  font-size: 1rem;
`;
