import React, { FC } from 'react';
import styled from 'styled-components/macro';
import HostModal, { HostModalProps, HostModalValue } from './HostModal';

export type EditHostModalValue = HostModalValue;

type EditHostModalProps = Omit<HostModalProps, 'title' | 'spinnerText' | 'submitButtonText'>;

const EditHostModal: FC<EditHostModalProps> = ({ onSubmit, value, ...rest }) => (
  <HostModal
    {...rest}
    value={value}
    onSubmit={onSubmit}
    title="Edit Host"
    spinnerText="Saving"
    submitButtonText="Save"
  />
);

export default styled(EditHostModal)``;
