import React, { FC, FormEvent, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { colors } from './constants';
import { MessageIcon, PlusIcon, ThumbtackIcon, EnvelopeIcon } from '../icons';
import { useDiscussionContext } from './DiscussionContext';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import DiscussionChat from './DiscussionChat';
import DiscussionHostChatLobby from './DiscussionHostChatLobby';

export enum DiscussionPromptTab {
  PROMPT = 'PROMPT',
  NOTES = 'NOTES',
  CHAT = 'CHAT',
}

interface DiscussionPromptProps {
  hasUnviewedMessage: boolean;
}

const DiscussionPrompt: FC<DiscussionPromptProps> = ({ hasUnviewedMessage, ...rest }) => {
  const {
    userId,
    discussion,
    setPrompt,
    settingPrompt,
    setNotes,
    settingNotes,
    activeChatUserId,
    setActiveChatUserId,
    discussionPromptTab,
    setDiscussionPromptTab,
    discussionPromptOpen,
    setDiscussionPromptOpen,
  } = useDiscussionContext();
  const isHost = isDiscussionHost(discussion, userId);
  const readOnly = !isDiscussionHost(discussion, userId);
  const [promptInputValue, setPromptInputValue] = useState(discussion.prompt);
  const [notesInputValue, setNotesInputValue] = useState(discussion.notes);
  const [showPromptChangeIcon, setShowPromptChangeIcon] = useState(false);
  const [showNotesChangeIcon, setShowNotesChangeIcon] = useState(false);

  const handlePromptInputChange = useCallback((event: FormEvent<HTMLTextAreaElement>) => {
    setPromptInputValue(event.currentTarget.value);
  }, []);

  const handleNotesInputChange = useCallback((event: FormEvent<HTMLTextAreaElement>) => {
    setNotesInputValue(event.currentTarget.value);
  }, []);

  const savePrompt = useCallback(() => {
    setPrompt(promptInputValue);
  }, [setPrompt, promptInputValue]);

  const saveNotes = useCallback(() => {
    setNotes(notesInputValue);
  }, [setNotes, notesInputValue]);

  const open = useCallback(() => {
    setDiscussionPromptOpen(true);
  }, [setDiscussionPromptOpen]);

  const close = useCallback(() => {
    setDiscussionPromptOpen(false);
  }, [setDiscussionPromptOpen]);

  const showPrompt = useCallback(() => {
    setDiscussionPromptTab(DiscussionPromptTab.PROMPT);
    setDiscussionPromptOpen(true);
  }, [setDiscussionPromptOpen, setDiscussionPromptTab]);

  const showNotes = useCallback(() => {
    setDiscussionPromptTab(DiscussionPromptTab.NOTES);
    setDiscussionPromptOpen(true);
  }, [setDiscussionPromptOpen, setDiscussionPromptTab]);

  const showChat = useCallback(() => {
    setDiscussionPromptTab(DiscussionPromptTab.CHAT);
    setDiscussionPromptOpen(true);
  }, [setDiscussionPromptOpen, setDiscussionPromptTab]);

  useEffect(() => {
    setPromptInputValue(discussion.prompt);
    if (discussion.prompt.trim().length > 0) {
      setShowPromptChangeIcon(true);
    }
  }, [discussion.prompt]);

  useEffect(() => {
    setNotesInputValue(discussion.notes);
    if (discussion.notes.trim().length > 0) {
      setShowNotesChangeIcon(true);
    }
  }, [discussion.notes]);

  /**
   * Hide the prompt change icon when the user views the new prompt.
   */
  useEffect(() => {
    if (discussionPromptOpen && showPromptChangeIcon && discussionPromptTab === DiscussionPromptTab.PROMPT) {
      setShowPromptChangeIcon(false);
    }
  }, [showPromptChangeIcon, discussionPromptTab, discussionPromptOpen]);

  /**
   * Hide the notes change icon when the user views the new prompt.
   */
  useEffect(() => {
    if (discussionPromptOpen && showNotesChangeIcon && discussionPromptTab === DiscussionPromptTab.NOTES) {
      setShowNotesChangeIcon(false);
    }
  }, [showNotesChangeIcon, discussionPromptTab, discussionPromptOpen]);

  const nav = (
    <Nav>
      <NavButton selected={discussionPromptTab === DiscussionPromptTab.PROMPT} onClick={showPrompt}>
        <MessageIcon />
        {showPromptChangeIcon && !isHost && <ChangeIcon />}
      </NavButton>
      <NavButton selected={discussionPromptTab === DiscussionPromptTab.NOTES} onClick={showNotes}>
        <ThumbtackIcon />
        {showNotesChangeIcon && !isHost && <ChangeIcon />}
      </NavButton>
      <NavButton selected={discussionPromptTab === DiscussionPromptTab.CHAT} onClick={showChat}>
        <EnvelopeIcon />
        {hasUnviewedMessage && <ChangeIcon />}
      </NavButton>
    </Nav>
  );

  if (!discussionPromptOpen) {
    return (
      <Root {...rest} open={false}>
        <Header justifyContent="flex-end">{nav}</Header>
        <OpenButton onClick={open}>
          <PlusIcon />
        </OpenButton>
      </Root>
    );
  }

  if (discussionPromptTab === DiscussionPromptTab.PROMPT) {
    return (
      <Root {...rest} open>
        <Header>
          <Title>Discussion Prompt</Title>
          {nav}
        </Header>
        <Textarea
          placeholder={readOnly ? 'Discussion prompt.' : 'Type a discussion prompt here.'}
          value={promptInputValue}
          onChange={handlePromptInputChange}
          readOnly={readOnly}
        />
        <SendButton
          visible={!readOnly && promptInputValue !== discussion.prompt}
          onClick={savePrompt}
          disabled={settingPrompt}
        >
          Send to Circle
        </SendButton>
        <CloseButton onClick={close} />
      </Root>
    );
  }
  if (discussionPromptTab === DiscussionPromptTab.NOTES) {
    return (
      <Root {...rest} open>
        <Header>
          <Title>Discussion Notes</Title>
          {nav}
        </Header>
        <Textarea
          placeholder={readOnly ? 'Discussion notes.' : 'Type discussion notes here.'}
          value={notesInputValue}
          onChange={handleNotesInputChange}
          readOnly={readOnly}
        />
        <SendButton
          visible={!readOnly && notesInputValue !== discussion.notes}
          onClick={saveNotes}
          disabled={settingNotes}
        >
          Send to Circle
        </SendButton>
        <CloseButton onClick={close} />
      </Root>
    );
  }
  if (discussionPromptTab === DiscussionPromptTab.CHAT) {
    const chatUserId = isHost ? activeChatUserId : discussion.ownerId;
    const activeChatParticipant = (chatUserId && discussion.participants[chatUserId]) || null;

    if (activeChatParticipant) {
      // User has selected someone to talk to (this defaults to the host as a participant)
      return (
        <Root {...rest} open>
          <Header>
            <Title>Messages</Title>
            {nav}
          </Header>
          <DiscussionChat participant={activeChatParticipant} onBack={() => setActiveChatUserId(null)} />
          <CloseButton onClick={close} />
        </Root>
      );
    } else {
      return (
        <Root {...rest} open>
          <Header>
            <Title>Messages</Title>
            {nav}
          </Header>
          <DiscussionHostChatLobby onSelectUser={setActiveChatUserId} />
          <CloseButton onClick={close} />
        </Root>
      );
    }
  }
  return null; // this shouldn't be possible
};

export default styled(DiscussionPrompt)``;

const Root = styled('div')<{ open: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 1rem;
  right: 1rem;
  box-sizing: border-box;

  width: ${props => (props.open ? '19.5rem' : 'auto')};
  height: ${props => (props.open ? '19.5rem' : '4.25rem')};
  padding-top: 0.5rem;
  border: 6px solid ${colors.DARK_PURPLE};
  border-radius: 1rem;
  background-color: ${colors.PINK};
  color: white;
  z-index: 10;
`;

const OpenButton = styled('button')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  right: 0px;
  bottom: 0;
  transform: translate(50%, 50%);
  height: 2rem;
  width: 2rem;
  background-color: ${colors.DARK_PURPLE};
  border-radius: 50%;
  border: none;
  color: ${colors.LIGHT_BLUE};
`;

const Header = styled('div')<{ justifyContent?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'space-between'};
  padding: 0 0 0.5rem 0;
  margin: 0 1rem 0 1rem;
  flex-shrink: 0;
`;

const Title = styled('h3')`
  color: inherit;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 0 0 0;
`;

const Textarea = styled('textarea')`
  resize: none;
  overflow: auto;
  width: auto;
  min-width: 0;
  flex: 1 1 auto;
  border: none;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-weight: 1rem;
  padding: 0.25rem 1rem 0.5rem 1rem;

  &::placeholder {
    color: white;
    font-style: italic;
    opacity: 1;
  }
`;

const SendButton = styled('button')<{ visible: boolean }>`
  border: none;
  box-sizing: border-box;
  flex-shrink: 0;
  transition: height 0.4s;
  padding: 0 0 0 0;
  height: ${props => (props.visible ? '2.5rem' : '0')};
  width: 100%;
  color: inherit;
  font-size: 1rem;
  font-weight: bold;
  background-color: ${colors.DARK_PURPLE};
  overflow: hidden;
`;

const CloseButton = styled('button')`
  position: absolute;
  bottom: -1rem;
  right: -0.85rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${colors.DARK_PURPLE};
  border: none;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 3px;
    width: 65%;
    border-radius: 2px;
    background-color: ${colors.LIGHT_BLUE};
  }
`;

const Nav = styled('div')`
  display: flex;
  flex-direction: row;
`;

const NavButton = styled('button')<{ selected: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  background-color: #ba29a6;
  border: none;
  font-size: 1.25rem;
  color: ${props => (props.selected ? 'white' : '#751A69')};

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 10%;
    bottom: 1px;
    height: 3px;
    width: 80%;
    border-radius: 1.5px;
    background-color: ${props => (props.selected ? '#0F014A' : 'transparent')};
  }

  & + & {
    margin-left: 1.25rem;
  }
`;

const ChangeIcon = styled('div')`
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  background-color: #acf00b;
  position: absolute;
  right: -0.2rem;
  top: -0.2rem;
`;
