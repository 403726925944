import { css, keyframes } from 'styled-components/macro';

export const colors = {
  DARK_PURPLE: '#0F014A',
  PURPLE: '#1C0678',

  BLUE: '#1E3DA2',
  LIGHT_BLUE: '#206AC5',
  LIGHTER_BLUE: '#277fea',

  PINK: '#C92DB4',
  DARK_PINK: '#9b2389',
  GREEN: '#ACF00B',
};

export const dimensions = {
  // Dimensions that are a percentage of the RadialLayout radius.
  CENTER_CIRCLE_RADIUS: 0.6, // Center circle
  CENTER_CIRCLE_OUTER_RADIUS: 0.75, // Circle around the center circle

  DEFAULT_LAYOUT_RADIUS: 320, // pixels
  PARTICIPANT_CIRCLE_RADIUS: 26, // pixels
  RIM_THICKNESS: 16, // pixels
};

export const TRANSITION_SPEED = '0.4s'; // CSS transition speed

export const animations = {
  fadeIn: css`
    opacity: 0;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-duration: ${TRANSITION_SPEED};
    animation-name: ${keyframes`
      from {
        opacity: 0;
      } to {
        opacity: 1;
      }
    `};
  `,
};
