import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Modal, { ModalProps } from '../Modal';
import PillButton from '../PillButton';

interface ConfirmLeaveDiscussionModalProps extends ModalProps {
  onConfirm(): unknown;
}

const ConfirmLeaveDiscussionModal: FC<ConfirmLeaveDiscussionModalProps> = ({ onConfirm, onClose, ...rest }) => (
  <Modal {...rest} onOverlayClick={onClose} onClose={onClose}>
    <Title>Are you sure you want to leave?</Title>
    <ConfirmButton onClick={onConfirm}>Leave</ConfirmButton>
  </Modal>
);

export default styled(ConfirmLeaveDiscussionModal)`
  text-align: center;
  align-items: center;
  padding-top: 9rem;
  padding-bottom: 8rem;
`;

const Title = styled('h1')`
  margin: 0 0 5rem 0;
  font-size: 2rem;
`;

const ConfirmButton = styled(PillButton)`
  width: 20rem;
`;
