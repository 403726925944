import React from 'react';
import { FC } from 'react';
import styled from 'styled-components/macro';

const EnableMicOverlay: FC<{ onRequestMicPermission(): unknown }> = ({ onRequestMicPermission, ...rest }) => (
  <div {...rest}>
    <Warning onClick={onRequestMicPermission}>Microphone not enabled</Warning>
  </div>
);

export default styled(EnableMicOverlay)`
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Warning = styled('button')`
  color: #f00;
  font-family: inherit;
  font-size: 1.5rem;
  text-align: center;
  background-color: transparent;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  border: none;
  width: auto;
`;
