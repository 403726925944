import { useEffect, useRef } from 'react';

type IsCancelled = () => boolean;
type Body = (isCancelled: IsCancelled) => Promise<unknown>;

function handleError(error: unknown) {
  console.error(error);
}

/**
 * Like `useEffect`, but allows an async function to be passed.
 * The body function can call its first argument to check whether
 * not the component has unmounted before updating state.
 *
 * Example:
 *
 * ```ts
 * useAsyncEffect(async isCancelled => {
 *   const post = await getPost(postId);
 *
 *   if(!isCancelled()) {
 *     setPost(post);
 *   }
 * }, [postId]);
 * ```
 */
export default function useAsyncEffect(body: Body, dependencies: ReadonlyArray<unknown> | undefined): void {
  const bodyRef = useRef(body);

  bodyRef.current = body;
  useEffect(() => {
    let cancelled = false;

    bodyRef.current(() => cancelled).catch(handleError);

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
