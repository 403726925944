import React, { FC, ComponentPropsWithoutRef, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import autosize from 'autosize';

const AutoSizeTextarea: FC<ComponentPropsWithoutRef<'textarea'>> = props => {
  const [el, setEl] = useState<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (!el) {
      return;
    }

    autosize(el);

    return () => {
      autosize.destroy(el);
    };
  }, [el]);

  return <textarea {...props} ref={setEl} />;
};

export default styled(AutoSizeTextarea)``;
