export const DEBUG = process.env.REACT_APP_DEBUG_APP === 'true';
export const IS_TEST = typeof describe === 'function' || typeof test === 'function';
export const ALLOW_GUEST_LOGIN = DEBUG;

/**
 * Where to redirect participants when a discussion ends.
 */
export const DISCUSSION_END_REDIRECT_URL =
  process.env.REACT_APP_DISCUSSION_END_REDIRECT_URL || 'https://www.peerlearning.net/';

/**
 * Where to redirect non-admin/host users when they navigate to the dashboard.
 */
export const PARTICIPANT_DASHBOARD_URL =
  process.env.REACT_APP_PARTICIPANT_DASHBOARD_URL || 'https://www.peerlearning.net/';

/**
 * Firebase config
 */
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

/**
 * Firebase emulator config
 */
export const FIREBASE_FIRESTORE_EMULATOR_HOST =
  process.env.REACT_APP_FIREBASE_FIRESTORE_EMULATOR_HOST || 'localhost:8080';
export const FIREBASE_FUNCTIONS_EMULATOR_HOST =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST || 'localhost:5001';
