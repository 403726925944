import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Modal, { ModalProps } from '../Modal';
import PillButton from '../PillButton';

interface ConfirmEndDiscussionModalProps extends ModalProps {
  onConfirm(): unknown;
}

const ConfirmEndDiscussionModal: FC<ConfirmEndDiscussionModalProps> = ({ onConfirm, onClose, ...rest }) => (
  <Modal {...rest} onOverlayClick={onClose} onClose={onClose}>
    <Title>End Listening Circles Session?</Title>
    <Message>End this session for all users</Message>
    <ConfirmButton onClick={onConfirm}>End Discussion for All</ConfirmButton>
  </Modal>
);

export default styled(ConfirmEndDiscussionModal)`
  text-align: center;
  align-items: center;
  padding-top: 9rem;
  padding-bottom: 8rem;
`;

const Title = styled('h1')`
  margin: 0 0 2rem 0;
  font-size: 2rem;
`;

const Message = styled('p')`
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 0 2.5rem 0;
  max-width: 18rem;
`;

const ConfirmButton = styled(PillButton)`
  width: 20rem;
`;
