import React, { FC } from 'react';
import styled from 'styled-components/macro';
import HostModal, { HostModalProps, HostModalValue } from './HostModal';

const initialValue: AddHostModalValue = {
  firstName: '',
  lastName: '',
  email: '',
};

export type AddHostModalValue = HostModalValue;

type AddHostModalProps = Omit<HostModalProps, 'value' | 'title' | 'spinnerText' | 'submitButtonText'>;

const AddHostModal: FC<AddHostModalProps> = ({ onSubmit, ...rest }) => (
  <HostModal
    {...rest}
    value={initialValue}
    onSubmit={onSubmit}
    title="Add a New Host"
    spinnerText="Adding"
    submitButtonText="Add User"
  />
);

export default styled(AddHostModal)``;
