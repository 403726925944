import React, { FC, ReactNode, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { Timestamp } from 'functions/lib/types/Timestamp';
import { MdDeleteForever as DeleteIcon } from 'react-icons/md';
import { PencilIcon } from './icons';

interface BlockListItemProps {
  expanded?: boolean;
  header: ReactNode;
  timestamp?: Timestamp;
  onDelete?(): unknown;
  onEdit?(): unknown;
}

const BlockListItem: FC<BlockListItemProps> = ({
  expanded: initiallyExpanded,
  header,
  timestamp,
  children,
  onDelete,
  onEdit,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(!!initiallyExpanded);

  const toggleExpanded = useCallback(() => {
    setExpanded(value => !value);
  }, []);

  return (
    <li {...rest}>
      {expanded && !!onDelete && (
        <ControlRow>
          <PencilIcon onClick={onEdit} />
          <Divider />
          <DeleteIcon size={23} onClick={onDelete} />
        </ControlRow>
      )}
      <Content highlighted={expanded} onClick={expanded ? undefined : toggleExpanded}>
        <Header onClick={expanded ? toggleExpanded : undefined}>{header}</Header>
        {expanded && children ? <ExpandContent>{children}</ExpandContent> : null}
      </Content>
    </li>
  );
};

export default styled(BlockListItem)`
  position: relative;
  padding: 0 0 0 0;
  & + & {
    margin-top: 1rem;
  }
`;

const Content = styled('div')<{ highlighted: boolean }>`
  position: relative;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 0.5rem;
  z-index: 2;
  background-color: ${props => (props.highlighted ? '#1C0678' : '#f2f2f2')};
  color: ${props => (props.highlighted ? 'white' : 'black')};
  cursor: pointer;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: none;
  max-height: none;
  z-index: 2;
`;

const ExpandContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Divider = styled('div')`
  height: 60%;
  width: 4px;
  border-radius: 2px;
  background-color: white;
  margin: 0 0.5rem 0 0.5rem;
`;

const ControlRow = styled('div')`
  position: absolute;
  top: 0;
  right: -6rem;
  height: 2.75rem;
  width: 12rem;
  color: #1c0678;
  background-color: #f4f4f4;
  border-radius: 1.25rem;
  z-index: 1;
  justify-content: flex-end;
  padding-right: 1rem;
  transition: color 0.2s;
  color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > svg {
    color: #1c0678;
    cursor: pointer;
  }

  & > svg:hover {
    color: #d00;
    background-color: #f4f4f4;
  }
`;
