import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components/macro';

const TypingIndicator: FC = props => (
  <div {...props}>
    <div />
    <div />
    <div />
  </div>
);

export default styled(TypingIndicator)`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(28, 6, 120, 45%);
  padding: 0.5rem;
  border-radius: 0.75rem;

  > div {
    width: 0.5rem;
    height: 0.5rem;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #1c0678;
    opacity: 0.2;
    animation-duration: 2s;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  > div + div {
    margin-left: 0.5rem;
  }

  > div:nth-child(1) {
    animation-name: ${keyframes`
      0% {
        opacity: 0.45;
      } 10% {
        opacity: 0.45;
      } 33% {
        opacity: 1;
      } 90% {
        opacity: 1;
      } 100% {
        opacity: 0.45;
      }
    `};
  }

  > div:nth-child(2) {
    animation-name: ${keyframes`
      0% {
        opacity: 0.45;
      } 33% {
        opacity: 0.45;
      } 66% {
        opacity: 1;
      } 90% {
        opacity: 1;
      } 100% {
        opacity: 0.45;
      }
    `};
  }

  > div:nth-child(3) {
    animation-name: ${keyframes`
      0% {
        opacity: 0.45;
      } 33% {
        opacity: 0.45;
      } 66% {
        opacity: 0.45;
      } 90% {
        opacity: 1;
      } 100% {
        opacity: 0.45;
      }
    `};
  }
`;
