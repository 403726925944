import { Callback } from '../../types';
import { isMobile } from '../../utils';
import * as TwilioVideo from 'twilio-video';
import { useCallback, useEffect, useState } from 'react';
import { DEBUG } from '../../env';

export default function useTwilioRoom(
  localTracks: TwilioVideo.LocalTrack[],
  onError: Callback,
  options?: TwilioVideo.ConnectOptions
) {
  const [twilioRoom, setTwilioRoom] = useState<TwilioVideo.Room | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  /**
   * Once the user has connected to the twilio room and granted audio/video permissions,
   * publish the local tracks to the twilio room.
   */
  useEffect(() => {
    if (twilioRoom) {
      for (const localTrack of localTracks) {
        twilioRoom.localParticipant.publishTrack(localTrack, { priority: 'standard' });
      }
    }
  }, [localTracks, twilioRoom]);

  const connect = useCallback(
    async token => {
      try {
        if (DEBUG) {
          console.log(`Connecting to voice room with token`, token);
        }
        setIsConnecting(true);
        const newRoom = await TwilioVideo.connect(token, { ...options, tracks: [] });
        setTwilioRoom(newRoom);

        const disconnect = () => newRoom.disconnect();

        newRoom.once('disconnected', () => {
          // Reset the room only after all other `disconnected` listeners have been called.
          setTimeout(() => setTwilioRoom(null));
          window.removeEventListener('beforeunload', disconnect);
          setIsConnected(false);

          if (isMobile) {
            window.removeEventListener('pagehide', disconnect);
          }
        });

        if (DEBUG) {
          (window as any).twilioRoom = newRoom;
        }

        // Add a listener to disconnect from the room when a user closes their browser
        window.addEventListener('beforeunload', disconnect);

        if (isMobile) {
          // Add a listener to disconnect from the room when a mobile user closes their browser
          window.addEventListener('pagehide', disconnect);
        }

        setIsConnected(true);
      } catch (error) {
        onError(error);
        setIsConnected(false);
      } finally {
        setIsConnecting(false);
      }
    },
    [options, onError]
  );

  const disconnect = useCallback(async () => {
    if (twilioRoom) {
      if (DEBUG) {
        console.log(`Disconnecting from twilio room.`);
      }
      await twilioRoom.disconnect();
    }
  }, [twilioRoom]);

  return { twilioRoom, isConnecting, isConnected, connect, disconnect };
}
