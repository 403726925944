import { useEffect } from 'react';

export function useUnmountEffect(fn: () => unknown): void {
  useEffect(() => {
    return () => {
      fn();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
