import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';

interface DashboardNavProps {
  showHostsLink: boolean;
}

const DashboardNav: FC<DashboardNavProps> = ({ showHostsLink, ...rest }) => {
  const circlesLinkMatch = useRouteMatch({
    path: '/',
    exact: true,
  });

  const hostsLinkMatch = useRouteMatch({
    path: '/hosts',
    exact: false,
  });

  return (
    <div {...rest}>
      <NavLink to="/">
        <div>My Circles</div>
        <ActiveBar hidden={!circlesLinkMatch} />
      </NavLink>
      {showHostsLink && (
        <NavLink to="/hosts">
          <div>Hosts</div>
          <ActiveBar hidden={!hostsLinkMatch} />
        </NavLink>
      )}
    </div>
  );
};

export default styled(DashboardNav)`
  display: flex;
  flex-direction: row;
`;

const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 1.25rem;
  font-weight: bold;

  & + & {
    margin-left: 3rem;
  }
`;

const ActiveBar = styled('div')`
  flex-shrink: 0;
  height: 6px;
  border-radius: 3px;
  width: 100%;
  background-color: #c92db4;
  margin-top: 0.15rem;
`;
