import React, { FC, createContext, useContext, useReducer, useState, useCallback, useEffect } from 'react';
import { TwilioError } from 'twilio-video';
import { AppUser } from 'functions/lib/types/AppUser';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useFirebaseAuth, { FirebaseAuthState } from './useFirebaseAuth';
import { User } from './User';
import { isUnsupportedBrowser } from '../utils/isUnsupportedBrowser';
import { isUnsupportedScreenSize } from '../utils/isUnsupportedScreenSize';

export interface StateContextType extends FirebaseAuthState {
  error: TwilioError | null;
  setError(error: Error | TwilioError | null): void;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
}

export const StateContext = createContext<StateContextType>(null!);

export const AppStateProvider: FC = ({ children }) => {
  const [error, setError] = useState<Error | TwilioError | null>(null);
  const [isFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  const handleError = useCallback((newError: Error | TwilioError | null) => {
    console.error(newError);
    setError(newError);
  }, []);

  /**
   * Immediately show an error if the user opens the app in an unsupported browser.
   */
  useEffect(() => {
    const errorMessages: string[] = [];
    if (isUnsupportedBrowser()) {
      errorMessages.push('You are using an unsupported browser.');
    }
    if (isUnsupportedScreenSize()) {
      errorMessages.push('Your screen is too small.');
    }
    if (errorMessages.length > 0) {
      setError(
        Object.assign(new Error(`${errorMessages.join(' ')} Some features may not work as intended.`), {
          warning: true,
        })
      );
    }
  }, []);

  const contextValue: StateContextType = {
    error,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    setError: handleError,
    ...useFirebaseAuth(),
  } as StateContextType;

  return <StateContext.Provider value={contextValue}>{children}</StateContext.Provider>;
};

export function useAppState(): StateContextType {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

export function useSecureAppState(): StateContextType & { user: User; appUser: AppUser } {
  const context = useAppState();

  if (!context.user || !context.appUser) {
    throw new Error('useSecureAppState cannot be used unless the user is logged in.');
  }

  return context as any;
}
