import React, { PropsWithChildren, MouseEventHandler, createContext, useMemo, useContext } from 'react';
import styled from 'styled-components/macro';

export interface RadialLayoutPosition {
  /**
   * The angle to position the child relative to the center of the layout.
   */
  angle: number;
  /**
   * The distance from the center to position the child. Value ranges from 0 (center) to 1 (edge).
   */
  distance: number;
}

type RadialLayoutProps = PropsWithChildren<{
  radius: number;
  style?: any;
  onClick?: MouseEventHandler<HTMLDivElement>;
}>;

type RadialLayoutChildProps = PropsWithChildren<{
  transitionType?: 'linear' | 'radial';
  position?: RadialLayoutPosition;
  style?: any;
  onClick?: MouseEventHandler<HTMLDivElement>;
}>;

/**
 * State passed down to `RadialLayoutChild` using `RadialLayoutContext`.
 */
interface RadialLayoutState {
  /**
   * The radius of the layout.
   */
  radius: number;
}

const RadialLayoutContext = createContext<RadialLayoutState>(null!);

/**
 * Radial layout. Items are positioned circularly around the center of the layout.
 */
export const RadialLayout = styled(({ radius, style: propStyle, ...rest }: RadialLayoutProps) => {
  const radialLayoutState: RadialLayoutState = useMemo(
    () => ({
      radius,
    }),
    [radius]
  );

  const style = {
    ...propStyle,
    width: radius * 2,
    height: radius * 2,
  };

  return (
    <RadialLayoutContext.Provider value={radialLayoutState}>
      <div {...rest} style={style} />
    </RadialLayoutContext.Provider>
  );
})`
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
`;

/**
 * Radial layout child. This should only be rendered directly under `RadialLayout`.
 */
export const RadialLayoutChild = styled(
  ({
    transitionType = 'linear',
    position = { angle: 0, distance: 0 },
    style: propStyle,
    ...rest
  }: RadialLayoutChildProps) => {
    const { radius } = useContext(RadialLayoutContext);
    let style = { ...propStyle };

    if (transitionType === 'linear') {
      // In this mode, elements will move directly from one point to another when transitioning between positions
      const x = position.distance * Math.cos(position.angle);
      const y = position.distance * Math.sin(position.angle);

      style.transform = `translate(-50%, -50%) translate(${radius + x}px, ${radius + y}px)`;
    } else {
      // In this mode, elements will rotate around the circle when transitioning between positions.
      style.transform = `translate(-50%, -50%) translate(${radius}px, ${radius}px) rotate(${
        position.angle
      }rad) translate(${position.distance}px, 0) rotate(${-position.angle}rad)`;
    }

    return <div {...rest} style={style} />;
  }
)`
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  transform-origin: center;
`;

export function getPerimeterPosition(
  index: number,
  count: number,
  distance: number,
  rotation = 0
): RadialLayoutPosition {
  return {
    distance,
    angle: getPerimeterAngle(index, count, rotation),
  };
}

export function getPerimeterAngle(index: number, count: number, rotation = 0) {
  return (
    -Math.PI / 2 + // Default offset (first item is at the top of the circle by default)
    rotation + // Additional offset passed by user
      (2 * Math.PI * index) / count || 0 // Offset based on index in array
  );
}
