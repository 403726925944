import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import { getCloudFunctionApiClient } from 'functions/lib/getCloudFunctionsClient';
import {
  DEBUG,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_FIRESTORE_EMULATOR_HOST,
  FIREBASE_FUNCTIONS_EMULATOR_HOST,
} from './env';

export const firebaseApp = firebase.initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
});

// Only persist logins for the life of a browser tab.
firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

export const db = firebaseApp.firestore();

export const functions = firebaseApp.functions();

export const api = getCloudFunctionApiClient(functions);

export function getCollection<T>(path: string) {
  return db.collection(path) as firebase.firestore.CollectionReference<T>;
}

export function getDoc<T>(path: string) {
  return db.doc(path) as firebase.firestore.DocumentReference<T>;
}

/**
 * Connect to the firestore/functions emulators if we're being served from localhost.
 */
if (window.location.hostname === 'localhost') {
  console.log('Connecting to firebase emulators', {
    FIREBASE_FIRESTORE_EMULATOR_HOST,
    FIREBASE_FUNCTIONS_EMULATOR_HOST,
  });
  db.settings({
    host: FIREBASE_FIRESTORE_EMULATOR_HOST,
    ssl: false,
  });

  functions.useFunctionsEmulator(FIREBASE_FUNCTIONS_EMULATOR_HOST);
}

/**
 * Allow access to the firebase app through the console as `window.app`
 */
if (DEBUG) {
  (window as any).app = firebaseApp;
}
