import React, { FC, ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { StorageImageObject } from 'functions/lib/types/StorageImageObject';
import { useStorageObjectUrl } from '../utils/useStorageImageUrl';

interface StorageImageProps extends Omit<ComponentPropsWithoutRef<'img'>, 'src'> {
  src: StorageImageObject | ComponentPropsWithoutRef<'img'>['src'] | null;
}

const StorageImage: FC<StorageImageProps> = ({ src, ...rest }) => {
  const imageUrl = useStorageObjectUrl(src);

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...rest} src={imageUrl || undefined} />;
};

export default styled(StorageImage)``;
