import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';
import { RadialLayoutChild } from '../RadialLayout';
import { colors, dimensions } from './constants';
import { MicIcon } from '../icons';
import MuteButton from './MuteButton';
import { useDiscussionContext } from './DiscussionContext';
import { canSpeak } from 'functions/lib/utils/canSpeak';
import useAudioContext from '../../hooks/useAudioContext/useAudioContext';

const LocalMuteControl: FC = props => {
  const { micPermissionRequested, getLocalAudioTrack } = useAudioContext();
  const { userId, discussion, muted, toggleMuted } = useDiscussionContext();
  const canLocalUserSpeak = canSpeak(discussion, userId);

  const handleMuteChange = useCallback(async () => {
    if (!micPermissionRequested) {
      await getLocalAudioTrack();
    }
    toggleMuted();
  }, [getLocalAudioTrack, toggleMuted, micPermissionRequested]);

  return (
    <RadialLayoutChild {...props}>
      <MuteButton
        visible={canLocalUserSpeak}
        onChange={handleMuteChange}
        muted={muted}
        backgroundColor={colors.PINK}
        iconColor={muted ? colors.PURPLE : 'white'}
      >
        <MicIcon />
      </MuteButton>
      <Label highlighted={!muted && canLocalUserSpeak}>
        You are {muted || !canLocalUserSpeak ? 'muted' : 'unmuted'}
      </Label>
    </RadialLayoutChild>
  );
};

export default styled(LocalMuteControl)`
  top: calc(${dimensions.CENTER_CIRCLE_OUTER_RADIUS * 50}% + 0.5rem);
  color: white;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MuteButton} {
    border: 6px solid #0f014a;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Label = styled('div')<{ highlighted: boolean }>`
  font-weight: 500;
  text-align: center;
  color: ${props => (props.highlighted ? 'white' : '#206AC5')};
  font-size: 0.9rem;
  font-weight: 500;
`;
