import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { RemoveIcon } from '../icons';
import Modal, { ModalProps } from '../Modal';
import PillButton from '../PillButton';

interface ConfirmKickModalProps extends ModalProps {
  participantName: string;
  onConfirm(): unknown;
}

const ConfirmKickModal: FC<ConfirmKickModalProps> = ({ participantName, onConfirm, onClose, ...rest }) => (
  <Modal {...rest} onOverlayClick={onClose} onClose={onClose}>
    <Icon />
    <Title>Remove {participantName}?</Title>
    <Message>Are you sure you want to remove this participant from this Listening Circles session?</Message>
    <ConfirmButton onClick={onConfirm}>Yes</ConfirmButton>
  </Modal>
);

export default styled(ConfirmKickModal)`
  text-align: center;
  align-items: center;
  padding-bottom: 8rem;
`;

const Icon = styled(RemoveIcon)`
  margin-bottom: 1rem;
`;

const Title = styled('h1')`
  margin: 0 0 2rem 0;
  font-size: 2rem;
`;

const Message = styled('p')`
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 0 2.5rem 0;
  max-width: 18rem;
`;

const ConfirmButton = styled(PillButton)`
  width: 20rem;
`;
