import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useDiscussionContext } from './DiscussionContext';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { SpeakingMode } from 'functions/lib/types/SpeakingMode';
import { colors } from './constants';

const CircleKindControl: FC = props => {
  const { userId, discussion, setSpeakingMode, settingCircleKind } = useDiscussionContext();

  const handleSequencedClick = useCallback(() => setSpeakingMode(SpeakingMode.SEQUENCED), [setSpeakingMode]);
  const handleNonSequencedClick = useCallback(() => setSpeakingMode(SpeakingMode.NON_SEQUENCED), [setSpeakingMode]);

  if (!isDiscussionHost(discussion, userId)) {
    return null;
  }

  return (
    <div {...props}>
      <Button
        onClick={handleSequencedClick}
        selected={discussion.speakingMode === SpeakingMode.SEQUENCED}
        disabled={settingCircleKind}
      >
        Sequential
      </Button>
      <Button
        onClick={handleNonSequencedClick}
        selected={discussion.speakingMode === SpeakingMode.NON_SEQUENCED}
        disabled={settingCircleKind}
      >
        Non-Sequential
      </Button>
    </div>
  );
};

export default styled(CircleKindControl)`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 1.5rem;
  top: 4.5rem;
`;

const Button = styled('button')<{ selected: boolean }>`
  box-sizing: border-box;
  border: 6px solid #13004d;
  color: ${props => (props.selected ? colors.GREEN : '#206AC5')};
  background-color: ${props => (props.selected ? '#0f014a' : '#180E73')};
  width: 7rem;
  padding: 0.5rem 0 0.5rem 0;
  margin: 0 0 0 0;
  font-size: 0.75rem;
  font-weight: bold;
  cursor: pointer;

  &:first-child {
    border-right-width: 0;
    border-radius: 1.5rem 0 0 1.5rem;
  }

  &:last-child {
    border-radius: 0 1.5rem 1.5rem 0;
    border-left-width: 0;
  }
`;
