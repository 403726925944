import { hasRaisedHand } from 'functions/lib/utils/hasRaisedHand';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { canRaiseHand } from 'functions/lib/utils/canRaiseHand';
import { HandIcon } from '../icons';
import { useDiscussionContext } from './DiscussionContext';

const RaiseHandButton: FC = props => {
  const { userId, discussion, raiseHand, raisingHand, lowerHand, loweringHand } = useDiscussionContext();
  const userIsRaisingHand = hasRaisedHand(discussion, userId) || raisingHand;

  if (!hasRaisedHand(discussion, userId) && !canRaiseHand(discussion, userId)) {
    return null;
  }

  return (
    <div {...props}>
      <Label>{userIsRaisingHand ? 'Your Hand is Raised' : 'Raise Hand'}</Label>
      <Button
        highlighted={userIsRaisingHand}
        onClick={userIsRaisingHand ? lowerHand : raiseHand}
        disabled={raisingHand || loweringHand}
      >
        <HandIcon />
      </Button>
    </div>
  );
};

export default styled(RaiseHandButton)`
  position: absolute;
  left: 1rem;
  bottom: 8rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled('button')<{ highlighted: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  height: 3.5rem;
  width: 3.5rem;
  border: 6px solid #0f014a;
  border-radius: 50%;
  background-color: #206ac5;
  color: ${props => (props.highlighted ? 'white' : '#0f014a')};
  min-width: 0;
  min-height: 0;
  font-size: 1.65rem;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
`;

const Label = styled('div')`
  color: white;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.5rem;
`;
