import React, { FC, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';

interface UserMenuProps {
  username: string;
  onLogout(): unknown;
}

const UserMenu: FC<UserMenuProps> = ({ username, onLogout, ...rest }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => setExpanded(value => !value), []);

  return (
    <div {...rest}>
      {expanded && (
        <OutsideClickHandler onOutsideClick={toggleExpanded}>
          <ExpandContent>
            <ExpandList>
              <ExpandListItem>
                <MenuButton onClick={onLogout}>Logout</MenuButton>
              </ExpandListItem>
            </ExpandList>
          </ExpandContent>
        </OutsideClickHandler>
      )}
      <MenuHead onClick={toggleExpanded}>{username.length > 0 ? username[0].toUpperCase() : 'U'}</MenuHead>
    </div>
  );
};

export default styled(UserMenu)`
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  position: relative;
`;

const MenuHead = styled('button')`
  position: absolute;
  right: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-family: 'Rubik', sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
  z-index: 10;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  box-sizing: border-box;
  border: 5px solid #1e3da2;
  background-color: #206ac5;
  cursor: pointer;
`;

const ExpandContent = styled('div')`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

const ExpandList = styled('ul')`
  list-style: none;
  margin: 0 0 0 0;
  padding: 0.35rem 2.5rem 0 0;
`;

const ExpandListItem = styled('li')`
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  height: 2.5rem;
`;

const MenuButton = styled('button')`
  margin: 0 0 0 0;
  padding: 0 0 0 1.25rem;
  position: relative;
  right: -2rem;
  border: none;
  height: 2.25rem;
  width: 10rem;
  border-radius: 1.25rem;
  background-color: #1e3da2;
  color: white;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
`;
