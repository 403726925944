import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import firebase from 'firebase';
import shuffle from 'lodash.shuffle';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Discussion } from 'functions/lib/types/Discussion';
import { DiscussionMessage } from 'functions/lib/types/DiscussionMessage';
import { paths } from 'functions/lib/paths';
import { isParticipantKicked } from 'functions/lib/utils/isParticipantKicked';
import { SpeakingMode } from 'functions/lib/types/SpeakingMode';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { ReactionEmoji } from 'functions/lib/types/ReactionEmoji';
import { getProfilePicturePath } from 'functions/lib/utils/getProfilePicturePath';
import { useSecureAppState } from '../state/index';
import Spinner from '../components/Spinner';
import DiscussionLayout, {
  ConfirmKickModal,
  ConfirmPassStickModal,
  ConfirmEndDiscussionModal,
  ConfirmLeaveDiscussionModal,
} from '../components/DiscussionLayout/DiscussionLayout';
import { useDoc } from '../hooks/useDoc';
import useLocalAudioToggle from '../hooks/useLocalAudioToggle/useLocalAudioToggle';
import { api, firebaseApp, getCollection, getDoc } from '../firebaseApp';
import useAudioContext from '../hooks/useAudioContext/useAudioContext';
import { DiscussionState, DiscussionContextProvider } from '../components/DiscussionLayout/DiscussionContext';
import { useUnmountEffect } from '../hooks/useUnmountEffect';
import { DISCUSSION_END_REDIRECT_URL } from '../env';
import { DiscussionPromptTab } from '../components/DiscussionLayout/DiscussionPrompt';
import EnableMicOverlay from '../components/EnableMicOverlay';

interface UrlParams {
  discussionId: string;
}

const DiscussionPage: FC = props => {
  const {
    connect,
    disconnect,
    isConnecting,
    isConnected,
    micPermissionGranted,
    getLocalAudioTrack,
  } = useAudioContext();
  const { user, appUser, setError } = useSecureAppState();
  const history = useHistory();
  const preJoined = useLocation<{ preJoined?: boolean }>().state?.preJoined ?? false; // whether or not the user joined before this componented rendered (used in the dashboard)
  const { discussionId } = useParams<UrlParams>();
  const [discussion] = useDoc<Discussion>(paths.discussion(discussionId));
  const [isUnmuted, toggleMuted] = useLocalAudioToggle();
  const [joiningDiscussion, setJoiningDiscussion] = useState(preJoined);
  const [passingStick, setPassingStick] = useState(false);
  const [takingStick, setTakingStick] = useState(false);
  const [raisingHand, setRaisingHand] = useState(false);
  const [loweringHand, setLoweringHand] = useState(false);
  const [swappingParticipants, setSwappingParticipants] = useState(false);
  const [removingParticipant, setRemovingParticipant] = useState(false);
  const [settingPrompt, setSettingPrompt] = useState(false);
  const [settingNotes, setSettingNotes] = useState(false);
  const [settingCircleKind, setSettingCircleKind] = useState(false);
  const [settingReactionEmoji, setSettingReactionEmoji] = useState(false);
  const [settingName, setSettingName] = useState(false);
  const [settingProfilePicture, setSettingProfilePicture] = useState(false);
  const [removingProfilePicture, setRemovingProfilePicture] = useState(false);
  const [endingDiscussion, setEndingDiscussion] = useState(false);
  const [togglingAllChat, setTogglingAllChat] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [confirmRemoveParticipantId, setConfirmRemoveParticipantId] = useState<string | null>(null);
  const [confirmEnd, setConfirmEnd] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState(false);
  const [confirmForcePass, setConfirmForcePass] = useState(false);
  const [showingNameTags, setShowingNameTags] = useState(false);
  const [shufflingParticipants, setShufflingParticipants] = useState(false);
  const [sentMessages, setSentMessages] = useState<DiscussionMessage[]>([]);
  const [receivedMessages, setReceivedMessages] = useState<DiscussionMessage[]>([]);
  const [activeChatUserId, setActiveChatUserId] = useState<string | null>(null);
  const [discussionPromptTab, setDiscussionPromptTab] = useState(DiscussionPromptTab.PROMPT);
  const [discussionPromptOpen, setDiscussionPromptOpen] = useState(false);
  const messages = useMemo(
    () =>
      [...sentMessages, ...receivedMessages].sort((a, b) => {
        const dateA = a.timestamp?.toDate().getTime();
        const dateB = b.timestamp?.toDate().getTime();
        if (!dateA && !dateB) {
          return 0;
        }
        if (!dateA) {
          return 1;
        }
        if (!dateB) {
          return -1;
        }
        return dateA - dateB;
      }),
    [sentMessages, receivedMessages]
  );
  const userId = user.uid;
  const localUsername = discussion?.participants[userId]?.name ?? user.displayName ?? '';
  const userIsHost = !!(discussion && isDiscussionHost(discussion, userId));
  const isUserConnected = !!discussion?.connectedParticipantIds.includes(userId);
  const hasDiscussionStarted = !!(discussion && discussion.startedAt);
  const hasBeenKicked = !!(discussion && isParticipantKicked(discussion, userId));
  const shouldDisconnect = !!(
    isConnected && // Connected to twilio room
    discussion && // Discussion is loaded
    !userIsHost && // Not the host
    // Discussion isn't started or user was kicked
    (!hasDiscussionStarted || hasBeenKicked)
  );
  const participantSeatingOrder = discussion?.participantSeatingOrder ?? [];
  const allChat = !!discussion?.allChat;
  const muted = !isUnmuted;

  const redirectAway = useCallback(() => {
    if (userIsHost) {
      history.push('/');
    } else {
      window.location.replace(DISCUSSION_END_REDIRECT_URL);
    }
  }, [userIsHost, history]);

  useEffect(() => {
    // Once the user successfully connects to the twilio room (signaled by the twilio status webhook)
    if (isUserConnected) {
      setJoiningDiscussion(false);
    }
  }, [isUserConnected]);

  /**
   * Request mic permissions from the host as soon as they begin the discussion.
   */
  useEffect(() => {
    if (userIsHost) {
      getLocalAudioTrack();
    }
  }, [getLocalAudioTrack, userIsHost]);

  /**
   * Disconnect from the twilio room when the discussion ends.
   */
  useEffect(() => {
    if (shouldDisconnect) {
      disconnect()
        .then(redirectAway)
        .catch(error => console.error('Failed to disconnect from twilio room', error));
    }
  }, [shouldDisconnect, disconnect, redirectAway]);

  /**
   * Reset profile picture flags when the profile picture changes.
   */
  useEffect(() => {
    setSettingProfilePicture(false);
    setRemovingProfilePicture(false);
  }, [appUser.profilePic]);

  /**
   * Load messages
   */
  useEffect(() => {
    // subscribe to incoming messages
    const cancel1 = getCollection<DiscussionMessage>(paths.discussionMessages(discussionId))
      .where('toUserId', '==', userId)
      .onSnapshot(snapshot => {
        const loadedMessages = snapshot.docs.map(doc => doc.data());
        setReceivedMessages(loadedMessages);
      });

    // subscribe to sent messages
    const cancel2 = getCollection<DiscussionMessage>(paths.discussionMessages(discussionId))
      .where('fromUserId', '==', userId)
      .onSnapshot(snapshot => {
        const loadedMessages = snapshot.docs.map(doc => doc.data());
        setSentMessages(loadedMessages);
      });

    return () => {
      cancel1();
      cancel2();
    };
  }, [discussionId, userId]);

  /**
   * Disconnect from the twilio room when the page component unmounted
   */
  useUnmountEffect(disconnect);

  const joinDiscussion = useCallback(async () => {
    if (isConnecting) {
      console.warn('Skipping joinDiscussion call: Connection already pending.');
      return;
    }
    if (isConnected) {
      console.warn('Skipping joinDiscussion call: Already connected.');
      return;
    }
    try {
      setJoiningDiscussion(true);
      const twilioJwt = await api.joinDiscussion({ discussionId, name: user.displayName ?? '' });
      await connect(twilioJwt);
    } catch (error) {
      setError(error);
    }
  }, [setError, connect, discussionId, user, isConnecting, isConnected]);

  const takeStick: DiscussionState['takeStick'] = useCallback(
    async options => {
      try {
        setTakingStick(true);
        await api.takeStick({ ...options, discussionId });
        if (muted && !options.passImmediately) {
          toggleMuted();
        }
      } catch (error) {
        setError(error);
      } finally {
        setTakingStick(false);
      }
    },
    [setError, toggleMuted, discussionId, muted]
  );

  const passStick = useCallback(async () => {
    try {
      setPassingStick(true);
      await api.passStick({ discussionId });
      if (!muted) {
        toggleMuted();
      }
    } catch (error) {
      setError(error);
    } finally {
      setPassingStick(false);
    }
  }, [setError, toggleMuted, discussionId, muted]);

  const forcePassStick = useCallback(async () => {
    if (!confirmForcePass) {
      setConfirmForcePass(true);
      return;
    }
    setConfirmForcePass(false);
    return passStick();
  }, [passStick, confirmForcePass]);

  const swapParticipants = useCallback(
    async (participantId1: string, participantId2: string) => {
      try {
        setSwappingParticipants(true);
        await getDoc<Discussion>(paths.discussion(discussionId)).update({
          participantSeatingOrder: participantSeatingOrder.map(id => {
            switch (id) {
              case participantId1:
                return participantId2;
              case participantId2:
                return participantId1;
              default:
                return id;
            }
          }),
        });
      } catch (error) {
        setError(error);
      } finally {
        setSwappingParticipants(false);
      }
    },
    [setError, discussionId, participantSeatingOrder]
  );

  const shuffleParticipants = useCallback(async () => {
    try {
      setShufflingParticipants(true);
      await getDoc<Discussion>(paths.discussion(discussionId)).update({
        participantSeatingOrder: shuffle(participantSeatingOrder),
      });
    } catch (error) {
      setError(error);
    } finally {
      setShufflingParticipants(false);
    }
  }, [setError, discussionId, participantSeatingOrder]);

  const raiseHand = useCallback(async () => {
    try {
      setRaisingHand(true);
      await api.raiseHand({ discussionId });
    } catch (error) {
      setError(error);
    } finally {
      setRaisingHand(false);
    }
  }, [setError, discussionId]);

  const lowerHand = useCallback(async () => {
    try {
      setLoweringHand(true);
      await api.lowerHand({ discussionId });
    } catch (error) {
      setError(error);
    } finally {
      setLoweringHand(false);
    }
  }, [setError, discussionId]);

  const setPrompt = useCallback(
    async (prompt: string) => {
      try {
        setSettingPrompt(true);
        await getDoc<Discussion>(paths.discussion(discussionId)).update({ prompt });
      } catch (error) {
        setError(error);
      } finally {
        setSettingPrompt(false);
      }
    },
    [setError, discussionId]
  );

  const setNotes = useCallback(
    async (notes: string) => {
      try {
        setSettingNotes(true);
        await getDoc<Discussion>(paths.discussion(discussionId)).update({ notes });
      } catch (error) {
        setError(error);
      } finally {
        setSettingNotes(false);
      }
    },
    [setError, discussionId]
  );

  const setProfilePicture = useCallback(
    async (file: File | null) => {
      try {
        if (file) {
          setSettingProfilePicture(true);
          await firebaseApp
            .storage()
            .ref(getProfilePicturePath(userId))
            .put(file, {
              customMetadata: {
                discussionId,
              },
            });
        } else {
          setRemovingProfilePicture(true);
          await api.clearProfilePicture({ discussionId });
        }
      } catch (error) {
        setError(error);
      }
    },
    [setError, discussionId, userId]
  );

  const setName = useCallback(
    async (name: string) => {
      try {
        setSettingName(true);
        await api.setName({ discussionId, name });
      } catch (error) {
        setError(error);
      } finally {
        setSettingName(false);
      }
    },
    [setError, discussionId]
  );

  const setReactionEmoji = useCallback(
    async (reactionEmoji: ReactionEmoji) => {
      try {
        setSettingReactionEmoji(true);
        await api.setReactionEmoji({ reactionEmoji, discussionId });
      } catch (error) {
        setError(error);
      } finally {
        setSettingReactionEmoji(false);
      }
    },
    [setError, discussionId]
  );

  const removeParticipant = useCallback(
    async (participantId: string) => {
      // Wait for confirmation
      if (confirmRemoveParticipantId !== participantId) {
        setConfirmRemoveParticipantId(participantId);
        return;
      }

      try {
        setRemovingParticipant(true);
        setConfirmRemoveParticipantId(null);
        await api.removeParticipant({ discussionId, participantId });
      } catch (error) {
        setError(error);
      } finally {
        setRemovingParticipant(false);
      }
    },
    [setError, discussionId, confirmRemoveParticipantId]
  );

  const leave = useCallback(async () => {
    if (!confirmLeave) {
      setConfirmLeave(true);
      return;
    }
    setConfirmLeave(false);
    await disconnect();
    redirectAway();
  }, [disconnect, confirmLeave, redirectAway]);

  const setSpeakingMode = useCallback(
    async (speakingMode: SpeakingMode) => {
      try {
        setSettingCircleKind(true);
        await getDoc<Discussion>(paths.discussion(discussionId)).update({ speakingMode });
      } catch (error) {
        setError(error);
      } finally {
        setSettingCircleKind(false);
      }
    },
    [setError, discussionId]
  );

  const endDiscussion = useCallback(async () => {
    if (!confirmEnd) {
      setConfirmEnd(true);
      return;
    }
    try {
      setEndingDiscussion(true);
      setConfirmEnd(false);
      await disconnect();
      await api.endDiscussion({ discussionId });
      redirectAway();
    } catch (error) {
      setError(error);
    } finally {
      setEndingDiscussion(false);
    }
  }, [setError, disconnect, discussionId, confirmEnd, redirectAway]);

  const toggleAllChat = useCallback(async () => {
    try {
      setTogglingAllChat(true);
      await getDoc<Discussion>(paths.discussion(discussionId)).update({ allChat: !allChat });
    } catch (error) {
      setError(error);
    } finally {
      setTogglingAllChat(false);
    }
  }, [setError, discussionId, allChat]);

  const toggleNameTags = useCallback(() => {
    setShowingNameTags(value => !value);
  }, []);

  const sendMessage = useCallback(
    async ({ toUserId, content }: { toUserId: string; content: string }) => {
      try {
        setSendingMessage(true);
        const messageDoc = getCollection<DiscussionMessage>(paths.discussionMessages(discussionId)).doc();
        const message: DiscussionMessage = {
          id: messageDoc.id,
          toUserId,
          content,
          viewed: false,
          fromUserName: localUsername,
          fromUserId: userId,
          timestamp: firebase.firestore.FieldValue.serverTimestamp() as any,
        };
        await messageDoc.set(message);
      } finally {
        setSendingMessage(false);
      }
    },
    [discussionId, userId, localUsername]
  );

  const markMessagesViewed = useCallback(
    async (fromUserId: string) => {
      await api.markMessagesViewed({ discussionId, fromUserId });
    },
    [discussionId]
  );

  const setStickReceiverTyping = useCallback(
    async (typing: boolean) => {
      await api.setStickReceiverTyping({ discussionId, typing });
    },
    [discussionId]
  );

  useEffect(() => {
    joinDiscussion();
  }, [joinDiscussion]);

  if (!discussion) {
    return (
      <div {...props}>
        <Spinner />
      </div>
    );
  }

  if (endingDiscussion) {
    return (
      <div {...props}>
        <Spinner message="Ending discussion" />
      </div>
    );
  }

  const discussionContextValue: DiscussionState = {
    muted: !isUnmuted,
    userId,
    user,
    appUser,
    discussion,
    joiningDiscussion,
    joinDiscussion,
    passingStick,
    passStick,
    forcePassStick,
    takingStick,
    takeStick,
    raisingHand,
    raiseHand,
    loweringHand,
    lowerHand,
    setPrompt,
    settingPrompt,
    setNotes,
    settingNotes,
    setName,
    settingName,
    setProfilePicture,
    settingProfilePicture,
    removingProfilePicture,
    leave,
    removingParticipant,
    swapParticipants,
    swappingParticipants,
    removeParticipant,
    settingCircleKind,
    setReactionEmoji,
    settingReactionEmoji,
    toggleMuted,
    setSpeakingMode,
    endingDiscussion,
    endDiscussion,
    togglingAllChat,
    toggleAllChat,
    showingNameTags,
    toggleNameTags,
    shufflingParticipants,
    shuffleParticipants,
    messages,
    sendingMessage,
    sendMessage,
    markMessagesViewed,
    activeChatUserId,
    setActiveChatUserId,
    discussionPromptTab,
    setDiscussionPromptTab,
    discussionPromptOpen,
    setDiscussionPromptOpen,
    setStickReceiverTyping,
  };

  return (
    <DiscussionContextProvider value={discussionContextValue}>
      <div {...props}>
        <DiscussionLayout />
        {!!confirmRemoveParticipantId && (
          <ConfirmKickModal
            participantName={discussion.participants[confirmRemoveParticipantId]?.name ?? ''}
            onConfirm={() => removeParticipant(confirmRemoveParticipantId)}
            onClose={() => setConfirmRemoveParticipantId(null)}
          />
        )}
        {confirmForcePass && (
          <ConfirmPassStickModal onConfirm={forcePassStick} onClose={() => setConfirmForcePass(false)} />
        )}
        {confirmEnd && <ConfirmEndDiscussionModal onConfirm={endDiscussion} onClose={() => setConfirmEnd(false)} />}
        {confirmLeave && <ConfirmLeaveDiscussionModal onConfirm={leave} onClose={() => setConfirmLeave(false)} />}
        {!micPermissionGranted && (
          <EnableMicOverlay
            onRequestMicPermission={() => {
              getLocalAudioTrack();
            }}
          />
        )}
      </div>
    </DiscussionContextProvider>
  );
};

export default styled(DiscussionPage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #180e73;
`;
