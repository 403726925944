import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { isParticipantKicked } from 'functions/lib/utils/isParticipantKicked';
import { useDiscussionContext } from './DiscussionContext';
import Select, { components as ReactSelectComponents } from 'react-select';
import { TriangleDownIcon } from '../icons';

interface DiscussionHostChatLobbyProps {
  onSelectUser(userId: string): unknown;
}

const DiscussionHostChatLobby: FC<DiscussionHostChatLobbyProps> = ({ onSelectUser, ...rest }) => {
  const { userId, discussion, messages } = useDiscussionContext();

  const otherParticipantsArray = Object.values(discussion.participants).filter(
    participant => participant.userId !== userId && !isParticipantKicked(discussion, participant.userId)
  );
  const participantsWithConversations = otherParticipantsArray.map(participant => ({
    participant,
    conversation: messages.filter(
      message => message.fromUserId === participant.userId || message.toUserId === participant.userId
    ),
  }));

  // Select a participant to chat with
  return (
    <div {...rest}>
      <Select
        options={otherParticipantsArray.map(participant => ({ value: participant.id, label: participant.name }))}
        onChange={event => event && onSelectUser(event.value)}
        placeholder="Choose participant"
        components={{
          DropdownIndicator: props => (
            <ReactSelectComponents.DropdownIndicator {...props}>
              <TriangleDownIcon size={20} color="#0F014A" style={{ transform: 'scale(2, 2.5)' }} />
            </ReactSelectComponents.DropdownIndicator>
          ),
        }}
        styles={{
          menu: provided => ({
            ...provided,
            backgroundColor: '#0F014A',
            borderRadius: '0 0 1rem 1rem',
            fontSize: '0.9rem',
            maxWidth: '230px',
            marginTop: '-0.5rem',
            boxShadow: 'none',
            padding: '0.5rem',
          }),
          menuList: provided => ({
            ...provided,
            borderTop: '2px solid white',
          }),
          placeholder: provided => ({
            ...provided,
            color: 'white',
            fontStyle: 'italic',
          }),
          option: provided => ({
            ...provided,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: '#206AC5',
            },
          }),
          input: provided => ({ ...provided, color: 'white' }),
          control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            backgroundColor: '#0F014A',
            borderColor: '#0F014A',
            borderRadius: state.menuIsOpen ? '1.25rem 1.25rem 1.25rem 0' : '1.25rem',
            overflow: 'hidden',
            height: '2.5rem',
            outline: 'none',
            boxShadow: 'none',
            '&:active': {
              borderColor: '#0F014A',
            },
            '&:hover': {},
            '&:focus': {},
          }),
          indicatorsContainer: provided => ({
            ...provided,
            backgroundColor: '#206AC5',
            paddingRight: '0.25rem',
          }),
          indicatorSeparator: () => ({ display: 'none' }),
        }}
      />

      <ActiveParticipantList>
        {participantsWithConversations
          .filter(({ conversation }) => conversation.length > 0)
          .map(({ participant, conversation }) => (
            <ActiveParticipantListItem key={participant.id} onClick={() => onSelectUser(participant.userId)}>
              <ActiveParticipantButton>
                <UnviewedMessageIcon
                  visible={conversation.some(message => !message.viewed && message.toUserId === userId)}
                />{' '}
                {participant.name}
              </ActiveParticipantButton>
            </ActiveParticipantListItem>
          ))}
      </ActiveParticipantList>
    </div>
  );
};

export default styled(DiscussionHostChatLobby)`
  padding: 0 0.9rem 0 0.9rem;
`;

const ActiveParticipantList = styled('ul')`
  list-style: none;
  padding: 0;
  overflow-y: auto;
  height: 11rem;
`;

const ActiveParticipantListItem = styled('li')`
  & + & {
    margin-top: 0.2rem;
  }
`;

const ActiveParticipantButton = styled('button')`
  padding: 1rem 1.75rem 1rem 0.75rem;
  background-color: #0f014a;
  color: white;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  width: 100%;
  height: auto;
  margin: 0;
  border: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UnviewedMessageIcon = styled('div')<{ visible: boolean }>`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #acf00b;
  margin-right: 0.6rem;
  transition: opacity 0.2s;
  opacity: ${props => (props.visible ? 1 : 0)};
`;
