import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DiscussionParticipant } from 'functions/lib/types/DiscussionParticipant';
import { useDiscussionContext } from './DiscussionContext';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { colors } from './constants';

const HostNameLabel: FC = props => {
  const { userId, discussion } = useDiscussionContext();
  const hostParticipant: DiscussionParticipant | undefined = discussion.participants[discussion.ownerId];

  // Only display for non-host participants
  if (isDiscussionHost(discussion, userId) || !hostParticipant) {
    return null;
  }

  return (
    <div {...props}>
      <Icon>H</Icon>
      <Name>{hostParticipant.name}</Name>
    </div>
  );
};

export default styled(HostNameLabel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 1.5rem;
  top: 4.5rem;
  color: white;
`;

const Icon = styled('div')`
  flex-shrink: 0;
  box-sizing: border-box;
  height: 2.25rem;
  width: 2.25rem;
  padding-top: 0.125rem;
  border-radius: 50%;
  border: 5px solid ${colors.BLUE};
  background-color: ${colors.LIGHT_BLUE};
  margin-right: 0.25rem;
  font-family: 'Rubik', sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
`;

const Name = styled('div')`
  font-size: 1rem;
  font-weight: 500;
`;
