import React, { FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import { AppUser } from 'functions/lib/types/AppUser';
import { AppUserInvite } from 'functions/lib/types/AppUserInvite';
import { sortByDate } from '../../utils/sortByDate';
import BlockList from '../BlockList';
import BlockListItem from '../BlockListItem';

interface UserListProps {
  userInvites: Pick<AppUserInvite, 'id' | 'email' | 'firstName' | 'lastName' | 'createdAt'>[];
  users: Pick<AppUser, 'id' | 'firstName' | 'lastName' | 'discussionCount' | 'createdAt'>[];
  onDeleteInvite?(inviteId: string): unknown;
  onDeleteUser?(userId: string): unknown;
  onEditInvite?(inviteId: string): unknown;
  onEditUser?(userId: string): unknown;
}

const UserList: FC<UserListProps> = ({
  userInvites,
  users,
  onDeleteUser,
  onDeleteInvite,
  onEditInvite,
  onEditUser,
  ...rest
}) => {
  const sortedUserInvites = useMemo(() => [...userInvites].sort((a, b) => sortByDate(a.createdAt, b.createdAt, true)), [
    userInvites,
  ]);
  const sortedUsers = useMemo(() => [...users].sort((a, b) => sortByDate(a.createdAt, b.createdAt, true)), [users]);

  return (
    <BlockList {...rest}>
      {sortedUserInvites.map(invite => (
        <BlockListItem
          key={invite.id}
          header={
            <ListItemHeader>
              <CenterRow>
                {invite.firstName} {invite.lastName} <PendingLabel>(pending)</PendingLabel>
              </CenterRow>
              <DiscussionCountText>0</DiscussionCountText>
            </ListItemHeader>
          }
          onDelete={() => onDeleteInvite && onDeleteInvite(invite.id)}
          onEdit={() => onEditInvite && onEditInvite(invite.id)}
        />
      ))}
      {sortedUsers.map(user => (
        <BlockListItem
          key={user.id}
          header={
            <ListItemHeader>
              <CenterRow>
                {user.firstName} {user.lastName}
              </CenterRow>
              <DiscussionCountText>{user.discussionCount}</DiscussionCountText>
            </ListItemHeader>
          }
          onDelete={() => onDeleteUser && onDeleteUser(user.id)}
          onEdit={() => onEditUser && onEditUser(user.id)}
        />
      ))}
    </BlockList>
  );
};

export default styled(UserList)``;

const PendingLabel = styled('span')`
  color: #c92db4;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 500;
  margin-left: 1.25rem;
`;

const ListItemHeader = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

const CenterRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DiscussionCountText = styled(CenterRow)`
  justify-content: space-between;
  font-size: 1.5rem;
  text-align: center;
  width: 2rem;
  padding-right: 2rem;

  &:before {
    content: '';
    display: block;
    background-color: white;
    width: 4px;
    border-radius: 2px;
    margin-right: 2rem;
    flex-shrink: 0;
    height: 100%;
  }
`;
