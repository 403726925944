import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { DiscussionParticipant } from 'functions/lib/types/DiscussionParticipant';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { isHoldingStick } from 'functions/lib/utils/isHoldingStick';
import { hasStickBeenPassed } from 'functions/lib/utils/hasStickBeenPassed';
import { SpeakingMode } from 'functions/lib/types/SpeakingMode';
import { ReactionEmoji } from 'functions/lib/types/ReactionEmoji';
import { RadialLayoutChild, getPerimeterPosition } from '../RadialLayout';
import { dimensions, colors, TRANSITION_SPEED } from './constants';
import { StarIcon, ArrowIcon, HandIcon, XIcon } from '../icons';
import { useSmoothAngle } from '../../hooks/useSmoothAngle';
import { useDiscussionContext } from './DiscussionContext';
import ParticipantAudioTrack from './ParticipantAudioTrack';
import { useStorageObjectUrl } from '../../utils/useStorageImageUrl';
import ReactionEmojiImage from './ReactionEmojiImage';

const RIM_OFFSET = dimensions.RIM_THICKNESS / 2; // center the participant circles in the border rim.
const REACTION_EMOJI_ANIMATION_DURATION = 3000; // milliseconds
enum NameTagPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

interface ParticipantCircleProps {
  seatingOrder: string[];
  layoutRadius: number;
  participant: DiscussionParticipant;
  expanded: boolean;
  onClick(participantId: string): unknown;
}

const ParticipantCircle: FC<ParticipantCircleProps> = ({
  seatingOrder,
  layoutRadius,
  participant,
  expanded,
  onClick,
  ...rest
}) => {
  const {
    userId,
    discussion,
    swapParticipants,
    swappingParticipants,
    removeParticipant,
    removingParticipant,
    showingNameTags,
  } = useDiscussionContext();

  const profileImageUrl = useStorageObjectUrl(participant.profilePic);
  const { speakingMode, handRaiseQueue, stickHolderPassEmoji } = discussion;
  const participantId = participant.id;
  const participantCount = discussion.connectedParticipantIds.length;
  const hasPassed = hasStickBeenPassed(discussion) && isHoldingStick(discussion, participantId);
  const isHost = isDiscussionHost(discussion, participantId);
  const isLocalUserHost = isDiscussionHost(discussion, userId);
  const isLocalUser = participantId === userId;
  const seatingIndex = seatingOrder.indexOf(participantId);
  const firstParticipantId = seatingOrder[0];
  const lastParticipantId = seatingOrder[seatingOrder.length - 1];
  const isFirst = participantId === firstParticipantId;
  const isLast = participantId === lastParticipantId;
  const prevParticipantId = isFirst ? lastParticipantId : seatingOrder[seatingIndex - 1];
  const nextParticipantId = isLast ? firstParticipantId : seatingOrder[seatingIndex + 1];
  const handRaiseIndex = speakingMode === SpeakingMode.NON_SEQUENCED ? handRaiseQueue.indexOf(participantId) : -1;
  const position = getPerimeterPosition(seatingIndex, seatingOrder.length, layoutRadius - RIM_OFFSET);
  const shouldShowNameTag = showingNameTags && !expanded && !hasPassed && !isLocalUser && !isHost;

  // Tangent angles for rotating the arrows to keep them always pointing clockwise/counterclockwise regardless of circle position
  const clockwiseArrowAngle = useSmoothAngle(Math.PI + position.angle);
  const counterClockwiseArrowAngle = useSmoothAngle(Math.PI + clockwiseArrowAngle);
  const [animatedReactionEmoji, setAnimatedReactionEmoji] = useState<ReactionEmoji | null>(null);
  const lastReactionEmojiSentAt = participant.lastReactionEmojiSentAt
    ? participant.lastReactionEmojiSentAt.toDate().getTime()
    : null;

  useEffect(() => {
    setAnimatedReactionEmoji(participant.reactionEmoji);
    if (participant.reactionEmoji) {
      const timeout = setTimeout(() => {
        setAnimatedReactionEmoji(null);
      }, REACTION_EMOJI_ANIMATION_DURATION);

      return () => clearTimeout(timeout);
    }
  }, [
    participant.reactionEmoji,
    // This dependency causes the effect to re-run even when same emoji is sent twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
    lastReactionEmojiSentAt,
  ]);

  const handleClick = useCallback(() => {
    onClick(participantId);
  }, [onClick, participantId]);

  if (seatingIndex === -1) {
    return null;
  }

  let icon: ReactNode = null;
  if (handRaiseIndex > -1) {
    icon = (
      <RaisedHand>
        <HandIcon />
        <RaisedHandNumber>{handRaiseIndex + 1}</RaisedHandNumber>
      </RaisedHand>
    );
  } else if (isHost) {
    icon = <HostIcon>H</HostIcon>;
  } else if (isLocalUser) {
    icon = (
      <StarIconWrapper>
        <StarIcon />
      </StarIconWrapper>
    );
  }

  let nameTagPosition: NameTagPosition;
  const middleSeat = Math.floor(seatingOrder.length / 2);
  const nearPoles =
    seatingIndex === middleSeat + 1 ||
    seatingIndex === middleSeat - 1 ||
    seatingIndex === 1 ||
    seatingIndex === seatingOrder.length - 1;

  if (seatingIndex === 0) {
    nameTagPosition = NameTagPosition.TOP;
  } else if (seatingIndex === middleSeat) {
    nameTagPosition = NameTagPosition.BOTTOM;
  } else if (seatingIndex > middleSeat) {
    nameTagPosition = NameTagPosition.LEFT;
  } else {
    nameTagPosition = NameTagPosition.RIGHT;
  }

  let nameTagZIndex: number = nearPoles ? 2 : 1;
  let zIndex: number;
  if (expanded || hasPassed) {
    // Show expand/pass bars above name tags and other participant circles
    zIndex = 30;
  } else if (nearPoles) {
    // Unexpaneded circles near top/bottom poles (but not on those poles) need to be slightly higher to overlap adjacent circles/name tags
    zIndex = 12;
    nameTagZIndex = 11;
  } else {
    // Unexpanded circles not near the poles are slightly lower
    zIndex = 10;
    nameTagZIndex = 9;
  }

  return (
    <>
      <Root {...rest} position={position} onClick={handleClick} transitionType="radial" zIndex={zIndex}>
        <Content expanded={expanded} highlighted={isHoldingStick(discussion, participantId)}>
          {isLocalUserHost && participantId !== userId && expanded && (
            <RemoveButton
              disabled={removingParticipant}
              onClick={event => {
                event.stopPropagation();
                removeParticipant(participantId);
              }}
            >
              <XIcon />
            </RemoveButton>
          )}
          <ParticipantAudioTrack participant={participant} hideVolumeIndicator={hasPassed && !expanded} />

          {expanded ? (
            <ExpandedContent justifyContent={isLocalUserHost ? 'space-between' : 'center'}>
              {isLocalUserHost && (
                <MoveParticipantButton
                  disabled={swappingParticipants}
                  onClick={event => {
                    event.stopPropagation();
                    if (prevParticipantId) {
                      swapParticipants(participantId, prevParticipantId);
                    }
                  }}
                >
                  <ArrowIcon style={{ transform: `scale(2) rotate(${counterClockwiseArrowAngle}rad)` }} />
                </MoveParticipantButton>
              )}
              <ParticipantNameLabel textLength={participant.name.length}>
                {participant.name}
                {hasStickBeenPassed(discussion) &&
                  isHoldingStick(discussion, participant.id) &&
                  stickHolderPassEmoji && <PassEmoji>{stickHolderPassEmoji}</PassEmoji>}
              </ParticipantNameLabel>
              {isLocalUserHost && (
                <MoveParticipantButton
                  disabled={swappingParticipants}
                  onClick={event => {
                    event.stopPropagation();
                    if (nextParticipantId) {
                      swapParticipants(participantId, nextParticipantId);
                    }
                  }}
                >
                  <ArrowIcon style={{ transform: `scale(2) rotate(${clockwiseArrowAngle}rad)` }} />
                </MoveParticipantButton>
              )}
            </ExpandedContent>
          ) : (
            <UnexpandedContent>
              <IconCircle backgroundImageSrc={profileImageUrl}>{icon}</IconCircle>
            </UnexpandedContent>
          )}

          {hasPassed && !expanded && (
            <PassOverlay
              onClick={event => {
                event.stopPropagation();
                onClick(participantId);
              }}
            >
              <IconCircle backgroundImageSrc={null}>{icon}</IconCircle>
              <PassText>Passed</PassText>
              <PassEmoji>{discussion.stickHolderPassEmoji}</PassEmoji>
            </PassOverlay>
          )}
        </Content>
      </Root>
      <FlyingReaction
        position={animatedReactionEmoji ? { angle: position.angle, distance: 0 } : position}
        transitionType="radial"
      >
        {animatedReactionEmoji && <ReactionEmojiImage reactionEmoji={animatedReactionEmoji} />}
      </FlyingReaction>
      <NameTagContainer position={position} zIndex={nameTagZIndex}>
        <NameTag
          position={nameTagPosition}
          faded={!shouldShowNameTag}
          size={participantCount > 25 ? 'small' : participantCount > 15 ? 'medium' : 'large'}
        >
          {participant.name}
        </NameTag>
      </NameTagContainer>
    </>
  );
};

export default styled(ParticipantCircle)``;

const Root = styled(RadialLayoutChild)<{ zIndex: number }>`
  z-index: ${props => props.zIndex};
`;

const Content = styled('div')<{ expanded: boolean; highlighted: boolean }>`
  display: flex;
  flex-direction: column;
  color: white;
  height: ${dimensions.PARTICIPANT_CIRCLE_RADIUS * 2}px;
  box-sizing: border-box;
  border-radius: ${Math.ceil(dimensions.PARTICIPANT_CIRCLE_RADIUS)}px;
  border: 5px solid ${colors.BLUE};
  transition: transform ${TRANSITION_SPEED}, background-color ${TRANSITION_SPEED}, width 0.2s;
  cursor: pointer;

  ${({ expanded, highlighted }) => {
    const backgroundColor = highlighted ? colors.PINK : colors.LIGHT_BLUE;

    if (expanded) {
      return css`
        width: 200px;
        background-color: ${backgroundColor};
      `;
    }

    return css`
      width: ${dimensions.PARTICIPANT_CIRCLE_RADIUS * 2}px;
      background-color: ${backgroundColor};
    `;
  }}
`;

const IconCircle = styled('div')<{ backgroundImageSrc: string | null }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex: 0 0 auto;
  width: ${dimensions.PARTICIPANT_CIRCLE_RADIUS * 2 * 0.8}px;
  height: 100%;
  border-radius: 50%;
  color: white;
  background-image: ${props => `url('${props.backgroundImageSrc}')` || 'none'};
  background-position: center;
  background-size: cover;
  z-index: 2;
`;

const PassOverlay = styled('div')`
  box-sizing: border-box;
  border-radius: ${Math.ceil(dimensions.PARTICIPANT_CIRCLE_RADIUS)}px;
  border: 5px solid ${colors.BLUE};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${colors.PINK};
  overflow: hidden;
  width: ${dimensions.PARTICIPANT_CIRCLE_RADIUS * 2}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  pointer-events: none;

  animation-name: ${keyframes`
    0% {
      width: ${dimensions.PARTICIPANT_CIRCLE_RADIUS * 2}px;
      color: rgba(255,255,255,0);
    } 10% {
      /* Expand */
      width: 180px;
      color: rgba(255,255,255,0);
    } 20% {
      /* Unfade text */
      width: 180px;
      color: rgba(255,255,255,1);
    } 100% {
      width: 180px;
      color: rgba(255,255,255,1);
    }
  `};
  animation-delay: 0.4s;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const StarIconWrapper = styled('div')`
  position: absolute;
  top: -0.2rem;
  right: -0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #206ac5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;

  ${StarIcon} {
    width: 100%;
    height: 100%;
  }
`;

const PassText = styled('div')`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
`;

const PassEmoji = styled('div')`
  font-size: 2rem;
  margin-left: 0.5rem;
`;

const HostIcon = styled('div')`
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
`;

const ExpandedContent = styled('div')<{ justifyContent: 'center' | 'space-between' }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  height: 100%;
  width: 100%;
  opacity: 0;
  animation-delay: 0.2s;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: ${keyframes`
    from {
      opacity: 0;
    } to {
      opacity: 1;
    }
  `};
`;

const UnexpandedContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ParticipantNameLabel = styled('div')<{ textLength: number }>`
  font-size: ${props => (props.textLength > 30 ? 0.65 : 1)}rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MoveParticipantButton = styled('button')`
  background: none;
  border: none;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.DARK_PURPLE};
  font-size: inherit;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    color: ${colors.LIGHTER_BLUE};
  }

  > svg {
    transition: transform ${TRANSITION_SPEED};
  }
`;

const RaisedHand = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;
  > svg {
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: ${colors.DARK_PURPLE};
    font-size: 1.7rem;
  }
`;

const RaisedHandNumber = styled('div')`
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`;

const RemoveButton = styled('button')`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-2.5rem, -50%);
  height: 2rem;
  width: 2rem;
  border: none;
  border-radius: 50%;
  padding: 0 0 0 0;
  font-size: 2rem;
  color: ${colors.LIGHT_BLUE};
  background-color: ${colors.DARK_PURPLE};
`;

const FlyingReaction = styled(RadialLayoutChild)`
  transition: transform ${REACTION_EMOJI_ANIMATION_DURATION / 1000}s;
`;

const NameTagContainer = styled(RadialLayoutChild)<{ zIndex: number }>`
  z-index: ${props => props.zIndex};
  height: 3rem;
`;

const NameTag = styled('div')<{ position: NameTagPosition; faded: boolean; size: 'small' | 'medium' | 'large' }>`
  position: absolute;
  background-color: #0f014a;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;
  text-align: center;
  transition: opacity 0.2s;
  opacity: ${props => (props.faded ? 0 : 1)};
  padding: 0 0.5rem 0 0.5rem;
  z-index: 10;


  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          height: 1.35rem;
          border-radius: 0.6125rem;
          font-size: 0.8rem;
          width: 9rem;
        `;
      case 'medium':
        return css`
          height: 3rem;
          border-radius: 1.5rem;
          font-size: 1.25rem;
          width: 9rem;
        `;
      case 'large':
        return css`
          height: 4rem;
          border-radius: 2rem;
          font-size: 1.5rem;
          width: 12rem;
        `;
    }
  }}

  /* position */
  ${({ position }) => {
    switch (position) {
      case NameTagPosition.LEFT:
        return css`
          right: 100%;
          top: 50%;
          transform: translate(0, -50%);
          justify-content: flex-end;
          padding-right: 2.5rem;
        `;
      case NameTagPosition.RIGHT:
        return css`
          left: 100%;
          top: 50%;
          transform: translate(0, -50%);
          padding-left: 2.5rem;
        `;
      case NameTagPosition.TOP:
        return css`
          left: 50%;
          top: 0;
          transform: translate(-50%, -100%);
          justify-content: center;
        `;
      case NameTagPosition.BOTTOM:
        return css`
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%);
          justify-content: center;
        `;
    }
  }}
`;
