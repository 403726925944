import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { PhotoIcon, ShuffleIcon } from '../icons';
import { colors } from './constants';
import MuteButton from './MuteButton';
import { useDiscussionContext } from './DiscussionContext';

const CornerControls: FC = props => {
  const {
    userId,
    discussion,
    showingNameTags,
    togglingAllChat,
    shufflingParticipants,
    toggleNameTags,
    toggleAllChat,
    shuffleParticipants,
  } = useDiscussionContext();
  const showHostControls = isDiscussionHost(discussion, userId);

  const handlePhotoTabClick = useCallback(() => {
    if (showingNameTags) {
      toggleNameTags();
    }
  }, [toggleNameTags, showingNameTags]);

  const handleNameTagTabClick = useCallback(() => {
    if (!showingNameTags) {
      toggleNameTags();
    }
  }, [toggleNameTags, showingNameTags]);

  return (
    <div {...props}>
      {showHostControls && (
        <MuteButton
          visible
          backgroundColor={colors.DARK_PURPLE}
          iconColor={discussion.allChat ? colors.GREEN : colors.PURPLE}
          muted={!discussion.allChat}
          disabled={togglingAllChat}
          onChange={toggleAllChat}
        />
      )}
      <Tabs>
        <TabButton onClick={handlePhotoTabClick} selected={!showingNameTags} first>
          <PhotoIcon size={15} />
        </TabButton>
        <TabButton onClick={handleNameTagTabClick} selected={showingNameTags}>
          Abc
        </TabButton>
      </Tabs>
      {showHostControls && (
        <ShuffleButton disabled={shufflingParticipants} onClick={shuffleParticipants}>
          <ShuffleIcon />
        </ShuffleButton>
      )}
    </div>
  );
};

export default styled(CornerControls)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 1.5rem;
  bottom: 4.5rem;

  ${MuteButton} {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }
`;

const Tabs = styled('div')`
  display: flex;
  flex-direction: row;
`;

const TabButton = styled('button')<{ selected: boolean; first?: boolean }>`
  box-sizing: border-box;
  border: 6px solid #13004d;
  color: ${props => (props.selected ? colors.GREEN : colors.LIGHT_BLUE)};
  background-color: ${props => (props.selected ? '#0f014a' : '#180E73')};
  outline: none;
  width: 3rem;
  height: 2.5rem;
  margin: 0 0 0 0;
  font-size: 0.75rem;
  flex-shrink: 0;
  font-weight: bold;
  cursor: pointer;

  ${props =>
    props.first
      ? css`
          border-right-width: 0;
          border-radius: 1.25rem 0 0 1.25rem;
        `
      : css`
          border-radius: 0 1.25rem 1.25rem 0;
          border-left-width: 0;
        `}
`;

const ShuffleButton = styled('button')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  height: 2.5rem;
  width: 4.125rem;
  border-radius: 1.25rem;
  background-color: #0f014a;
  color: #206ac5;
  font-size: 2.5rem;
  margin-left: 1rem;
`;
