import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Scrollbar } from 'react-scrollbars-custom';
import { TriangleDownIcon } from './icons';

const PinkScrollbarContainer: FC<{ style?: any }> = props => {
  return (
    <Scrollbar
      {...props}
      trackYProps={{
        renderer: ({ elementRef, children, ...rest }) => (
          <CustomScrollTrack {...(rest as any)} ref={elementRef}>
            <CustomScrollTrackCapTop>
              <TriangleDownIcon />
            </CustomScrollTrackCapTop>
            {children}
            <CustomScrollTrackCapBottom>
              <TriangleDownIcon />
            </CustomScrollTrackCapBottom>
          </CustomScrollTrack>
        ),
      }}
      thumbYProps={{
        renderer: ({ elementRef, ...rest }) => (
          <CustomScrollThumb
            {...(rest as any)}
            ref={elementRef}
            style={{
              height: rest.style?.height,
              transform: rest.style?.transform,
            }}
          />
        ),
      }}
    />
  );
};

export default styled(PinkScrollbarContainer)``;

const CustomScrollThumb = styled('div')`
  border-radius: 0.5rem;
  background-color: #c92db4;
`;

const CustomScrollTrack = styled('div')`
  border-radius: 0.5rem;
  border: 1px solid #c92db4;
`;

const CustomScrollTrackCap = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  height: 0.75rem;
  background-color: #c92db4;
  color: #0f014a;
  pointer-events: none;
  text-align: center;
  font-size: 100%;
  z-index: 2;
  svg {
    position: relative;
    left: -3px;
    top: -1px;
  }
`;

const CustomScrollTrackCapTop = styled(CustomScrollTrackCap)`
  top: 0;

  svg {
    top: -2px;
    transform: rotate(180deg);
  }
`;

const CustomScrollTrackCapBottom = styled(CustomScrollTrackCap)`
  bottom: 0;
`;
