import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { TakeStickIcon } from '../icons';
import Modal, { ModalProps } from '../Modal';
import PillButton from '../PillButton';

interface ConfirmPassStickModalProps extends ModalProps {
  onConfirm(): unknown;
}

const ConfirmPassStickModal: FC<ConfirmPassStickModalProps> = ({ onConfirm, onClose, ...rest }) => (
  <Modal {...rest} onOverlayClick={onClose} onClose={onClose}>
    <Icon />
    <Title>Take the Stick?</Title>
    <Message>Take the stick and pass it to the next participant?</Message>
    <ConfirmButton onClick={onConfirm}>Pass Stick</ConfirmButton>
  </Modal>
);

export default styled(ConfirmPassStickModal)`
  text-align: center;
  align-items: center;
  padding-bottom: 8rem;
`;

const Icon = styled(TakeStickIcon)`
  margin-bottom: 1rem;
`;

const Title = styled('h1')`
  margin: 0 0 2rem 0;
  font-size: 2rem;
`;

const Message = styled('p')`
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 0 2.5rem 0;
  max-width: 18rem;
`;

const ConfirmButton = styled(PillButton)`
  width: 20rem;
`;
