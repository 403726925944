import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { dimensions } from './constants';
import { RadialLayoutChild, getPerimeterPosition } from '../RadialLayout';

const choices = ['😀', '😟', '😠', '😕', '😧', '😲', '🚫'].reverse();
const choiceCount = choices.length;

interface CenterEmojiInputProps {
  layoutRadius: number;
  onEmojiClick(emoji: string): unknown;
}

const CenterEmojiInput: FC<CenterEmojiInputProps> = ({ layoutRadius, onEmojiClick }) => {
  return (
    <>
      {choices.map((emoji, i) => (
        <EmojiChoice
          key={i}
          position={getPerimeterPosition(
            i - 3,
            choiceCount * 3.5,
            layoutRadius * dimensions.CENTER_CIRCLE_RADIUS - 32,
            Math.PI
          )}
        >
          <EmojiButton onClick={() => onEmojiClick(emoji)}>{emoji}</EmojiButton>
        </EmojiChoice>
      ))}
    </>
  );
};

export default CenterEmojiInput;

const EmojiChoice = styled(RadialLayoutChild)`
  font-size: 1.5rem;
`;

const EmojiButton = styled('button')`
  width: auto;
  height: auto;
  cursor: pointer;
  background: none;
  margin: 0 0 0 0;
  border: none;
  font-size: 1.5rem;
`;
