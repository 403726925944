/**
 * Gets the shortest distance between two angles in radians.
 */
export function getShortestAngleDifference(radians1: number, radians2: number): number {
  const diff = ((radiansToDegrees(radians1) - radiansToDegrees(radians2) + 180) % 360) - 180;
  return degreesToRadians(diff < -180 ? diff + 360 : diff);
}

/**
 * Convert from radians to degrees.
 */
export function radiansToDegrees(radians: number): number {
  return (180 * radians) / Math.PI;
}

/**
 * Convert from degrees to radians.
 */
export function degreesToRadians(degrees: number): number {
  return (Math.PI * degrees) / 180;
}
