import React, { FC, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { isDiscussionHost } from 'functions/lib/utils/isDiscussionHost';
import { hasStickBeenPassed } from 'functions/lib/utils/hasStickBeenPassed';
import { canTakeStick } from 'functions/lib/utils/canTakeStick';
import { MAX_PASS_MESSAGE_LENGTH } from 'functions/lib/constants/maxPassMessageLength';
import { hasConnectedToDiscussion } from 'functions/lib/utils/hasConnectedToDiscussion';
import { DiscussionParticipant } from 'functions/lib/types/DiscussionParticipant';
import { isParticipantKicked } from 'functions/lib/utils/isParticipantKicked';
import { getFocusedParticipantId } from 'functions/lib/utils/getFocusedParticipantId';
import { SpeakingMode } from 'functions/lib/types/SpeakingMode';
import { RadialLayoutChild } from '../RadialLayout';
import { colors, dimensions } from './constants';
import { useDiscussionContext } from './DiscussionContext';
import CenterEmojiInput from './CenterEmojiInput';
import { useStorageObjectUrl } from '../../utils/useStorageImageUrl';
import Spinner from '../Spinner';
import ReactionEmojiMenu from './ReactionEmojiMenu';
import { MicIcon, XIcon } from '../icons';
import AutoSizeTextarea from '../AutoSizeTextarea';
import PinkScrollbarContainer from '../PinkScrollbarContainer';
import TypingIndicator from '../TypingIndicator';
import useAudioContext from '../../hooks/useAudioContext/useAudioContext';

interface CenterCircleProps {
  layoutRadius: number;
}

const CenterCircle: FC<CenterCircleProps> = ({ layoutRadius, ...rest }) => {
  const { getLocalAudioTrack, micPermissionRequested } = useAudioContext();
  const {
    userId,
    appUser,
    discussion,
    removingProfilePicture,
    settingProfilePicture,
    joinDiscussion,
    joiningDiscussion,
    passStick,
    passingStick,
    takeStick,
    takingStick,
    setReactionEmoji,
    setStickReceiverTyping,
  } = useDiscussionContext();
  const [speakState, setSpeakState] = useState<null | 'choosing-message-type' | 'entering-message'>(null);
  const [showPassMessage, setShowPassMessage] = useState<null | {
    userId: string;
    username: string;
    message: string;
    visible: boolean;
  }>(null);
  const [passMessage, setPassMessage] = useState('');
  const { participants } = discussion;
  const localParticipant = (participants[userId] || null) as DiscussionParticipant | null;
  const focusedParticipantId = getFocusedParticipantId(discussion);
  const focusedParticipant: DiscussionParticipant | undefined = participants[focusedParticipantId];
  const isHost = isDiscussionHost(discussion, userId);
  const isParticipant = !isHost;
  const isStarted = !!discussion.startedAt;
  const isConnected = hasConnectedToDiscussion(discussion, userId);
  const isLocalParticipant = focusedParticipantId === userId;
  const localUserName = localParticipant?.name ?? appUser.displayName;
  const isLocalUserKicked = isParticipantKicked(discussion, userId);
  const backgroundImageSrc = useStorageObjectUrl(
    (removingProfilePicture || settingProfilePicture) && isLocalParticipant ? null : focusedParticipant?.profilePic
  );
  const stickHolderName = discussion.participants[discussion.stickHolderId]?.name ?? '';
  const canUserTakeStick = canTakeStick(discussion, userId);

  /**
   * When nobody has their hand up in a non-sequenced circle, the host can take/pass the stick.
   * Passing with a text message is disabled in this case to avoid the host being cut-off while
   * typing by someone raising their hand (which would give the hand raiser priority over the host to take the stick).
   */
  const hidePassWithTextOption =
    isHost && discussion.speakingMode === SpeakingMode.NON_SEQUENCED && discussion.handRaiseQueue.length === 0;

  const requireMicPermission = useCallback(async () => {
    if (!micPermissionRequested) {
      await getLocalAudioTrack();
    }
  }, [micPermissionRequested, getLocalAudioTrack]);

  // Reset the message type menu when the user takes/passes the stick.
  useEffect(() => {
    if (!canUserTakeStick) {
      setSpeakState(null);
      setPassMessage('');
    }
  }, [canUserTakeStick]);

  /**
   * When navigating between the choose message type and enter message views, keep the server informed
   * so other participants can see the typing indicator.
   */
  useEffect(() => {
    if (speakState === 'entering-message' && !discussion.stickReceiverTyping) {
      setStickReceiverTyping(true);
    } else if (speakState === 'choosing-message-type' && discussion.stickReceiverTyping) {
      setStickReceiverTyping(false);
    }
  }, [setStickReceiverTyping, speakState, discussion]);

  // Display pass messages when they're set
  useEffect(() => {
    if (discussion.stickHolderHasPassed && discussion.stickHolderMessage) {
      setShowPassMessage(value => {
        if (value && value.userId === discussion.stickHolderId) {
          return value;
        }
        return {
          username: stickHolderName,
          userId: discussion.stickHolderId,
          message: discussion.stickHolderMessage ?? '',
          visible: true,
        };
      });
    }
  }, [discussion.stickHolderHasPassed, discussion.stickHolderMessage, discussion.stickHolderId, stickHolderName]);

  if (!isStarted && isParticipant) {
    return (
      <Root {...rest} backgroundImageSrc={null} justifyContent="center">
        <ParticipantNameText>{localUserName}</ParticipantNameText>
        <div>Waiting for host to begin the discussion.</div>
      </Root>
    );
  }

  if (isLocalUserKicked) {
    return (
      <Root {...rest} backgroundImageSrc={null} justifyContent="center">
        <ParticipantNameText>{localUserName}</ParticipantNameText>
        <div>You have been removed from this discussion.</div>
      </Root>
    );
  }

  if (joiningDiscussion) {
    return (
      <Root {...rest} backgroundImageSrc={null}>
        <Spinner
          message={`${isHost && !isStarted ? 'Beginning' : 'Joining'} discussion`}
          color="white"
          backgroundColor={colors.DARK_PURPLE}
        />
      </Root>
    );
  }

  if (!isConnected) {
    return (
      <Root {...rest} backgroundImageSrc={null} justifyContent="center">
        <ParticipantNameText>{localUserName}</ParticipantNameText>
        <Button onClick={joinDiscussion} disabled={joiningDiscussion}>
          {isHost && !isStarted ? 'Begin' : 'Join'} Discussion
        </Button>
      </Root>
    );
  }

  if (showPassMessage && showPassMessage.visible) {
    return (
      <Root {...rest} backgroundImageSrc={null} justifyContent="flex-start" padding="2.5rem 4rem 4rem 4rem">
        <TopXButton onClick={() => setShowPassMessage({ ...showPassMessage, visible: false })}>
          <XIcon />
        </TopXButton>
        <PinkScrollbarContainer style={{ height: '8rem', width: '14rem', marginTop: '1rem' }}>
          <MessageTextArea readOnly value={showPassMessage.message} />
        </PinkScrollbarContainer>
        <ParticipantNameText>{showPassMessage.username}</ParticipantNameText>
        {showPassMessage.userId !== userId && <ReactionEmojiMenu onEmojiSelect={setReactionEmoji} />}
      </Root>
    );
  }

  // Current user can take the stick. Present it to them.
  if (canUserTakeStick) {
    if (speakState === 'entering-message') {
      return (
        <Root {...rest} backgroundImageSrc={null} justifyContent="flex-start" padding="2rem 4rem 4rem 4rem">
          <TopXButton onClick={() => setSpeakState('choosing-message-type')}>
            <XIcon />
          </TopXButton>
          <PinkScrollbarContainer style={{ height: '8rem', width: '14rem', marginTop: '1rem' }}>
            <MessageTextArea
              placeholder="Enter your response here..."
              value={passMessage}
              maxLength={MAX_PASS_MESSAGE_LENGTH}
              onChange={event => setPassMessage(event.currentTarget.value)}
            />
          </PinkScrollbarContainer>
          <MessageSubmitButton onClick={() => takeStick({ passImmediately: true, passEmoji: null, passMessage })}>
            I'm done
          </MessageSubmitButton>
        </Root>
      );
    } else if (speakState === 'choosing-message-type') {
      return (
        <Root {...rest} backgroundImageSrc={null} justifyContent="flex-start">
          <TopXButton onClick={() => setSpeakState(null)}>
            <XIcon />
          </TopXButton>
          <Title>{localUserName}</Title>
          <MessageTypeInstructions>Choose how you want to share.</MessageTypeInstructions>
          <MessageTypeRow>
            <MessageTypeButton
              onClick={async () => {
                await requireMicPermission();
                takeStick({ passImmediately: false, passEmoji: null, passMessage: null });
              }}
            >
              <MicIcon />
            </MessageTypeButton>
            <MessageTypeButton onClick={() => setSpeakState('entering-message')}>Abc</MessageTypeButton>
          </MessageTypeRow>
        </Root>
      );
    } else {
      return (
        <>
          <Root {...rest} backgroundImageSrc={null}>
            <Title>It’s Your Turn</Title>
            <Button
              onClick={async () => {
                if (hidePassWithTextOption) {
                  await requireMicPermission();
                  takeStick({ passImmediately: false, passEmoji: null, passMessage: null });
                } else {
                  // Open the message type menu.
                  setSpeakState('choosing-message-type');
                }
              }}
              disabled={takingStick}
            >
              I Want to Share
            </Button>
            <PassStickMessage>or choose an option below to</PassStickMessage>
            <PassButton onClick={() => takeStick({ passImmediately: true, passEmoji: null, passMessage: null })}>
              Pass
            </PassButton>
          </Root>
          <CenterEmojiInput
            layoutRadius={layoutRadius}
            onEmojiClick={emoiji => takeStick({ passImmediately: true, passEmoji: emoiji, passMessage: null })}
          />
        </>
      );
    }
  }

  // Someone is speaking or deciding whether they want to take/pass the stick
  if (focusedParticipant) {
    if (focusedParticipant.id === userId) {
      // Local user. Display their name and the pass stick button if it hasn't been passed
      return (
        <Root {...rest} backgroundImageSrc={backgroundImageSrc}>
          <ParticipantNameText>{focusedParticipant.name}</ParticipantNameText>
          {isDiscussionHost(discussion, focusedParticipant.id) && <HostLabel>Host</HostLabel>}
          {hasStickBeenPassed(discussion) ? (
            <SubTitle>
              You passed
              <br />
              {discussion.stickHolderPassEmoji}
            </SubTitle>
          ) : (
            <Button onClick={passStick} disabled={passingStick}>
              I'm Done
            </Button>
          )}
        </Root>
      );
    } else {
      // Other user. Just display their name and show their photo
      return (
        <Root {...rest} backgroundImageSrc={backgroundImageSrc}>
          {isDiscussionHost(discussion, focusedParticipant.id) && <HostLabel>Host</HostLabel>}
          {discussion.stickReceiverTyping && hasStickBeenPassed(discussion) && <TypingIndicator />}
          <ParticipantNameText>{focusedParticipant.name}</ParticipantNameText>
          {!hasStickBeenPassed(discussion) && <ReactionEmojiMenu onEmojiSelect={setReactionEmoji} />}
        </Root>
      );
    }
  }

  return <Root {...rest} backgroundImageSrc={backgroundImageSrc} />;
};

export default styled(CenterCircle)``;

const Root = styled(RadialLayoutChild)<{
  backgroundImageSrc: string | null;
  justifyContent?: string;
  padding?: string;
}>`
  width: ${dimensions.CENTER_CIRCLE_RADIUS * 100}%;
  height: ${dimensions.CENTER_CIRCLE_RADIUS * 100}%;
  border: 1rem solid ${colors.PINK};
  background-color: ${colors.DARK_PURPLE};
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: ${({ padding = '4rem' }) => padding};
  align-items: center;
  background-image: ${props => (props.backgroundImageSrc ? `url('${props.backgroundImageSrc}')` : 'none')};
  background-position: center;
  background-size: cover;
  justify-content: ${props => props.justifyContent || 'flex-end'};

  ${Spinner} {
    border-radius: 50%;
  }

  h1,
  h2,
  h3 {
    text-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  }
`;

export const CenterOuterBorder = styled(RadialLayoutChild)`
  background-color: ${colors.GREEN};
  width: ${dimensions.CENTER_CIRCLE_OUTER_RADIUS * 100}%;
  height: ${dimensions.CENTER_CIRCLE_OUTER_RADIUS * 100}%;
  opacity: 0.82;
  border-radius: 50%;
`;

const Title = styled('h1')`
  min-height: 7rem;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
  margin: 0 0 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ParticipantNameText = styled('h1')`
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
`;

const SubTitle = styled('h2')`
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  height: 3rem;
`;

const HostLabel = styled('h2')`
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
`;

const Button = styled('button')`
  padding: 0.75rem 2.5rem 0.75rem 2.5rem;
  border-radius: 1.5rem;
  background-color: ${colors.PINK};
  color: white;
  font-size: 1.25rem;
  text-align: center;
  border: none;
  margin: 0 0 0 0;
  max-width: none;
  min-width: 0;
  width: 15rem;
  font-weight: 500;
  flex-shrink: 0;
  transition: background-color 0.2s, opacity 0.2s;

  &:hover:not(:disabled) {
    background-color: ${colors.DARK_PINK};
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const PassStickMessage = styled('div')`
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
`;

const PassButton = styled('button')`
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  border: none;
  width: auto;
  height: auto;
  flex-shrink: 0;
  background-color: transparent;
`;

const MessageTypeInstructions = styled('label')`
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
`;

const MessageTypeRow = styled('div')`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: center;
  margin-top: 0.7rem;
`;

const MessageTypeButton = styled('button')`
  color: white;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border: none;
  width: 4.875rem;
  height: 2rem;
  flex-shrink: 0;
  background-color: #1c0678;

  &:first-child {
    margin-right: 0.125rem;
    border-radius: 1rem 0 0 1rem;
  }

  &:last-child {
    border-radius: 0 1rem 1rem 0;
  }
`;

const TopXButton = styled('button')`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 1.75rem;
  height: 1.75rem;
  background-color: #0f014a;
  border-radius: 50%;
  color: #206ac5;
  border: none;
  padding: 0 0 0 0;
  margin: 0 0 0 0;

  > * {
    width: 100%;
    height: 100%;
  }
`;

const MessageTextArea = styled(AutoSizeTextarea)`
  color: white;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  resize: none;
  width: 100%;
  font-family: inherit;
  font-size: 1rem;
  padding: 0 1rem 0 1rem;
  outline: none;
  box-sizing: border-box;
  min-height: 7rem;

  &::placeholder {
    color: white;
    opacity: 1;
    font-style: italic;
  }
`;

const MessageSubmitButton = styled('button')`
  width: 100%;
  height: 3rem;
  color: white;
  border: none;
  border-radius: 1.5rem;
  background-color: #c92db4;
  margin-top: 2rem;
  font-size: 1.25rem;
`;
