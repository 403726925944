import 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import { firebaseApp } from '../firebaseApp';
import { User } from './User';
import { useDoc } from '../hooks/useDoc';
import { paths } from 'functions/lib/paths';
import { AppUser } from 'functions/lib/types/AppUser';

export interface FirebaseAuthState {
  appUser: AppUser | null;
  user: User | null;
  isAuthReady: boolean;
  signInWithGoogle(): Promise<void>;
  signInAsGuest(): Promise<void>;
  signOut(): Promise<void>;
}

export default function useFirebaseAuth(): FirebaseAuthState {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [appUser] = useDoc<AppUser>(user ? paths.appUser(user.uid) : null);
  const appUserExists = !!appUser;

  useEffect(() => {
    return firebaseApp.auth().onAuthStateChanged(updatedUser => {
      setUser(updatedUser);
      if (!updatedUser) {
        setIsAuthReady(true);
      }
    });
  }, []);

  useEffect(() => {
    if (appUserExists) {
      setIsAuthReady(true);
    }
  }, [appUserExists]);

  const signInWithGoogle = useCallback(async () => {
    await firebaseApp
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider().addScope('https://www.googleapis.com/auth/plus.login'));
  }, []);

  const signInAsGuest = useCallback(async () => {
    await firebaseApp.auth().signInAnonymously();
  }, []);

  const signOut = useCallback(async () => {
    await firebaseApp.auth().signOut();
  }, []);

  return { appUser, user, signInWithGoogle, signInAsGuest, signOut, isAuthReady };
}
