import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { ReactionEmoji } from 'functions/lib/types/ReactionEmoji';
import { ReactComponent as ClapEmoji } from '../../images/reaction-emoijs/clap.svg';
import { ReactComponent as EarEmoji } from '../../images/reaction-emoijs/ear.svg';
import { ReactComponent as HeartsEmoji } from '../../images/reaction-emoijs/hearts.svg';
import { ReactComponent as HugEmoji } from '../../images/reaction-emoijs/hug.svg';
import { ReactComponent as LikeEmoji } from '../../images/reaction-emoijs/like.svg';

interface ReactionEmojiImageProps {
  reactionEmoji: ReactionEmoji;
}

const ReactionEmojiImage: FC<ReactionEmojiImageProps> = ({ reactionEmoji, ...rest }) => {
  switch (reactionEmoji) {
    case ReactionEmoji.CLAP:
      return <ClapEmoji {...rest} />;
    case ReactionEmoji.EAR:
      return <EarEmoji {...rest} />;
    case ReactionEmoji.HEARTS:
      return <HeartsEmoji {...rest} />;
    case ReactionEmoji.HUG:
      return <HugEmoji {...rest} />;
    case ReactionEmoji.LIKE:
      return <LikeEmoji {...rest} />;
  }
};

export default styled(ReactionEmojiImage)``;
